import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { notification, Upload } from 'antd';

import {
  createProject, createRedditTaskDashboard, createReviewsTaskDashboard,
  createScrapersV2Task,
  getProjectTasks,
  getScrapersV2RepeatingTasks
} from '../../utils/ApiUtilsV5';
import { getFeatureFlags } from '../../featureFlagsSingleton';
import SidebarList from './SidebarList/SidebarList';
import SelectedSources from './SidebarList/SelectedSources';
import { validationSources } from './constants';
import { Button } from '../../components/core/Button';

const DisplayStreamData = ({ projectData, project }) => {

  const featureFlags = getFeatureFlags();
  const [projectTasksList, setProjectTasksList] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sendEmail, setSendEmail] = useState(true);
  const [skipSentiment, setSkipSentiment] = useState(true);
  const [repeat, setRepeat] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState('day');
  const [isLoading, setIsLoading] = useState(false);

  const getProjectTasksHandler = useCallback(async () => {
    if (featureFlags.new_scrapers) {

      const repeatingTasks = await getScrapersV2RepeatingTasks();

      const projectId = projectData.projectId;

      const projectRepeatingTasks = repeatingTasks.filter(
        task => (task.config.project_id === projectId)
      );
      const firstProjectRepeatingTask = projectRepeatingTasks[0];
      const isRedditUrl = url => /reddit*/.test(url);
      const task_type = firstProjectRepeatingTask.config.url_list.some(url =>
        isRedditUrl(url)
      )
        ? 'reddit'
        : 'reviews';
      const convertedTask = {
        lumi_api_url: firstProjectRepeatingTask.config.api_base,
        project_id: firstProjectRepeatingTask.config.project_id,
        task_type,
        url_list: firstProjectRepeatingTask.config.url_list,
        repeat: true,
        repeat_interval:
          firstProjectRepeatingTask.repeat_interval === 86400 ? 'day' : 'week',
        notify: firstProjectRepeatingTask.config.should_notify,
        skip_sentiment: firstProjectRepeatingTask.config.skip_sentiment
      };

      const projectTasks = [convertedTask];

      setProjectTasksList(projectTasks);
    } else {
      const projectTasks = await getProjectTasks(project.project_id);
      setProjectTasksList(projectTasks);
    }
  }, [project?.project_id]);

  useEffect(() => {
    if (project?.project_id) {
      void getProjectTasksHandler();
    }
  }, [getProjectTasksHandler, project?.project_id]);


  useEffect(() => {
    const fetchProjectTasks = async () => {
      try {
        if (!project?.project_id) return;

        const projectTasks = await getProjectTasks(project.project_id);

        if (Array.isArray(projectTasks) && projectTasks.length > 0) {
          const firstTask = projectTasks[0];

          if (firstTask?.url_list && Array.isArray(firstTask.url_list)) {
            const newSources = firstTask.url_list.map(url => ({
              id: uuidv4(),
              url,
              error: null
            }));

            setSelectedSources(prevSources =>
              JSON.stringify(prevSources) === JSON.stringify(newSources)
                ? prevSources
                : newSources
            );
          }
        }
      } catch (error) {
        console.error('Error fetching project tasks:', error);
      }
    };

    fetchProjectTasks();
  }, [project?.project_id]);

  useEffect(() => {
    if (projectTasksList.length > 0) {
      const firstTask = projectTasksList[0];

      setSelectedSources(
        firstTask.url_list.map((url) => ({
          id: uuidv4(),
          url,
          error: null
        }))
      );

      setSendEmail(firstTask.notify);
      setSkipSentiment(firstTask.skip_sentiment);
      setRepeat(firstTask.repeat);
      setRepeatInterval(firstTask.repeat_interval);
    }
  }, [projectTasksList]);

  const createProjectName = () => {
    const titles = selectedSources.map(source => source.name);
    return titles.join(' | ') + ' Scrape';
  };

  const handleAddSource = (source) => {
    setSelectedSources((prev) => [
      { ...source, id: uuidv4(), url: "", error: null },
      ...prev,
    ]);
  };


  const handleRemoveSource = (sourceId) => {
    setSelectedSources((prev) => prev.filter((s) => s.id !== sourceId));
  };

  const handleInputChange = (id, value) => {
    setSelectedSources((prev) =>
      prev.map((s) =>
        s.id === id ? { ...s, url: value, error: null } : s
      )
    );
  };

  const validateAllUrls = () => {
    let hasError = false;
    const updatedSources = selectedSources.map((source) => {
      const detectedSource = validationSources.find((s) =>
        s.validation.test(source.url)
      );
      if (!detectedSource) {
        hasError = true;
        return {
          ...source,
          error: `Invalid URL format for unknown source`
        };
      }
      const isValid = detectedSource.validation.test(source.url);
      if (!source.url || !isValid) {
        hasError = true;
        return {
          ...source,
          name: detectedSource.title.name,
          error: `Invalid URL format for ${detectedSource.title.name}`
        };
      }

      return {
        ...source,
        name: detectedSource.title.name,
        error: null
      };
    });

    setSelectedSources(updatedSources);

    return !hasError;
  };


  const handleCreateProject = async () => {
    const isValid = validateAllUrls();

    if (!isValid) {
      notification.error({
        message: "Invalid URLs",
        description: "Some URLs are invalid. Please correct them before proceeding.",
        duration: 3,
      });
      return;
    }

    try {
      setIsLoading(true);
      let existingProjectId = projectData.projectId;
      if (!existingProjectId) {
        const createdProject = await createProject(
          projectData.workspaceId,
          projectData.name || createProjectName(),
          projectData.language,
          projectData.description
        );

        if (!createdProject?.project_id) {
          notification.error({
            message: "Project Creation Failed",
            description: "Could not create the project. Please try again later.",
            duration: 3,
          });
          setIsLoading(false);
          return;
        }
        existingProjectId = createdProject.project_id;
      }
      const taskPayload = {
        project_id: existingProjectId,
        url_list: selectedSources.map((source) => source.url),
        repeat,
        repeat_interval: repeatInterval,
        token: localStorage.getItem('auth_token')?.replace(/['"]+/g, '') || null,
        should_notify: sendEmail,
        skip_sentiment: skipSentiment,
        api_base: `${window.location.protocol}//${window.location.host}`
      };

      if (featureFlags.new_scrapers) {
        await createScrapersV2Task(
          taskPayload.project_id,
          taskPayload.url_list,
          taskPayload.repeat,
          taskPayload.repeat_interval,
          taskPayload.token,
          taskPayload.should_notify,
          taskPayload.skip_sentiment
        );
      } else {
        const redditSources = selectedSources.filter(source => source.name === 'Reddit');
        const otherSources = selectedSources.filter(source => source.name !== 'Reddit');

        const taskPayloadReddit = {
          project_id: existingProjectId,
          url_list: redditSources.map((source) => source.url),
          repeat,
          repeat_interval: repeatInterval,
          token: localStorage.getItem('auth_token')?.replace(/['"]+/g, '') || null,
          should_notify: sendEmail,
          skip_sentiment: skipSentiment,
          api_base: `${window.location.protocol}//${window.location.host}`
        };


        const taskPayloadOther = {
          project_id: existingProjectId,
          url_list: otherSources.map((source) => source.url),
          repeat,
          repeat_interval: repeatInterval,
          token: localStorage.getItem('auth_token')?.replace(/['"]+/g, '') || null,
          should_notify: sendEmail,
          skip_sentiment: skipSentiment,
          api_base: `${window.location.protocol}//${window.location.host}`
        };

        if (taskPayloadReddit.url_list.length > 0) {
          await createRedditTaskDashboard(
            taskPayloadReddit.project_id,
            taskPayloadReddit.url_list,
            taskPayloadReddit.repeat,
            taskPayloadReddit.repeat_interval,
            taskPayloadReddit.token,
            taskPayloadReddit.should_notify,
            taskPayloadReddit.skip_sentiment
          );
        }
        if (taskPayloadOther.url_list.length > 0){
          await createReviewsTaskDashboard(
            taskPayloadOther.project_id,
            taskPayloadOther.url_list,
            taskPayloadOther.repeat,
            taskPayloadOther.repeat_interval,
            taskPayloadOther.token,
            taskPayloadOther.should_notify,
            taskPayloadOther.skip_sentiment
          );
        }
      }

      notification.success({
        message: "Project Created",
        description: "Your project has been created successfully!",
        duration: 2,
      });

      setTimeout(() => {
        window.location.replace(window.location.origin);
      }, 500);

    } catch (error) {
      notification.error({
        message: "Error Creating Project",
        description: error.message || "Something went wrong. Please try again.",
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const urls = e.target.result
          .split("\n")
          .map((line) => line.trim().replace(/^["']|["']$/g, ""))
          .filter((line) => line);

        const validSources = [];
        const invalidUrls = [];

        urls.forEach((url) => {
          const detectedSource = validationSources.find((source) =>
            source.validation.test(url)
          );

          if (detectedSource) {
            validSources.push({
              id: uuidv4(),
              url,
              name: detectedSource.title.name,
              error: null,
            });
          } else {
            invalidUrls.push(url);
          }
        });

        if (invalidUrls.length > 0) {
          notification.warning({
            message: "Invalid URLs Found",
            description: `Some URLs were invalid and were not added:\n${invalidUrls.join(", ")}`,
            duration: 5,
          });
        }

        setSelectedSources((prev) => [...prev, ...validSources]);
      };

      reader.readAsText(file);
    } catch (error) {
      notification.error({
        message: "File Upload Error",
        description: "There was an error reading the file.",
        duration: 3,
      });
    }
  };



  const onDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload(files[0]);
    }
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Container
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}>
      <ImportBlock>
        <p><b>Import your data</b> from anywhere by dropping your data spreadsheet here or</p>
        <Upload
          beforeUpload={(file) => {
            handleFileUpload(file);
            return false;
          }}
          showUploadList={false}
          accept=".txt,.csv"
        >
          <Button>Choose File</Button>
        </Upload>
      </ImportBlock>
      <h5>Stream data from the following publicly available sources:</h5>
      <ContentWrapper>
        <SidebarContainer>
          <SidebarList
            handleAddSource={handleAddSource}
          />
        </SidebarContainer>
        <SelectedContainer>
          <SelectedSources
            selectedSources={selectedSources}
            handleRemove={handleRemoveSource}
            handleInputChange={handleInputChange}
            handleCreateProject={handleCreateProject}
            sendEmail={sendEmail}
            setSendEmail={setSendEmail}
            sentiment={skipSentiment}
            setSentiment={setSkipSentiment}
            repeat={repeat}
            setRepeat={setRepeat}
            repeatInterval={repeatInterval}
            setRepeatInterval={setRepeatInterval}
            isLoading={isLoading}
          />
        </SelectedContainer>
      </ContentWrapper>
    </Container>
  );
};

export default DisplayStreamData;

const Container = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const SidebarContainer = styled.div`
  flex: 1; 
`;

const SelectedContainer = styled.div`
  flex: 8;  
`;

const ImportBlock = styled.div`
  padding: 15px;
  border: 1px dashed #ccc;
  text-align: center;
  border-radius: 5px;
  background-color: #f9f9f9;
`;
