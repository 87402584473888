import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_MESSAGE_TYPE, CHAT_MESSAGE_AUTHORS } from '../../../../types';
import { messages } from '../../../../utils/api';
import { getMessageType } from '../utils';
import {
  chatbotGenerate,
  llmPassthrough,
} from '../../../../../../utils/ApiUtilsV5';

const caughtSystemErrorMessage =
  'System error in generating reponse. Please try again, or contact Luminoso support for assistance.';

const sendMessage = (set, get) => async (message, type) => {
  const conversation = [...get().activeChat.messages];
  const history = conversation[conversation.length - 1]?.history || null;

  const userReply = {
    id: uuidv4(),
    content: message,
    role: CHAT_MESSAGE_AUTHORS.USER,
    type: CHAT_MESSAGE_TYPE.TEXT,
    created_at: new Date().toISOString(),
    history,
  };

  const fakeAssistantReply = {
    id: uuidv4(),
    content: i18next.t('chat.system_info.waiting_for_reply'),
    role: CHAT_MESSAGE_AUTHORS.SYSTEM,
    type: CHAT_MESSAGE_TYPE.AWAITING,
    created_at: new Date().toISOString(),
  };

  conversation.push(userReply);

  set((state) => ({
    ...state,
    isLoading: true,
    activeChat: {
      ...state.activeChat,
      isBotReplying: true,
      messages: [...conversation, fakeAssistantReply],
    },
  }));

  const store = get();
  const chatbotStatus = store.chatbotStatus;
  const chatbotKnowledgeBaseId = store.chatbotKnowledgeBaseId;
  const projectId = store.projectId;
  if (chatbotStatus !== 'ready') return;

  const docSearchResults = store.docSearchResults;
  const filter = store.filter;
  // console.log('docSearchResults');
  // console.log(docSearchResults);
  // console.log('filter');
  // console.log(filter);

  let response;
  let responseText;

  if (filter.length > 0) {
    // console.log('filter.length > 0. using filtered documents for context.');

    const context = docSearchResults.map((d) => d.text);
    // todo: any context limit or truncation?

    const prompt = `
      In the context of the document texts given below in the <context> tags,
      answer the prompt under the <prompt> tag:
      
      <context>
      ${context}
      </context>
      <prompt>
      ${message}
      </prompt>
    `;

    try {
      response = await llmPassthrough(projectId, prompt);
      responseText = response.response;
    } catch (e) {
      reponseText = caughtSystemErrorMessage;
    }
  } else {
    // console.log('filter.length == 0. using traditional rag pipeline.');
    try {
      response = await chatbotGenerate(projectId, message);
      responseText = response.text;
    } catch (e) {
      responseText = caughtSystemErrorMessage;
    }
  }

  const assistantReply = [
    {
      id: uuidv4(),
      content: responseText,
      role: 'assistant',
      type: 'text',
      created_at: new Date().toISOString(),
    },
  ];

  set((state) => ({
    ...state,
    activeChat: {
      ...state.activeChat,
      isBotReplying: false,
      isInteractionFromMessageRequired: assistantReply.some(
        ({ type }) => type === CHAT_MESSAGE_TYPE.BUTTONS,
      ),
      isAwaitingForFeedback: false,
      messages: [...conversation, ...assistantReply],
    },
  }));
};

export default sendMessage;
