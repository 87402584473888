export const SOURCE_CATEGORIES = {
  SOCIAL_MEDIA: { id: "socialMedia", title: "Social Media Forums" },
  REVIEW_SITES: { id: "reviewSites", title: "Review Sites" },
  EMPLOYEE_EXPERIENCE: { id: "employeeExperience", title: "Employee Experience" },
};


export const SOURCES = {
  REDDIT: { name: "Reddit", icon: "REDDIT", color: "#ff4b08", exampleUrl: "https://reddit.com/r/SUBREDDIT/comments/THREAD_NAME_IDENTIFIED_EXACTLY/" },
  AMAZON: { name: "Amazon", icon: "AMAZON", exampleUrl: "https://www.amazon.com/PRODUCT-NAME-EXACTLY/" },
  BESTBUY: { name: "Best Buy", icon: "BESTBUY", exampleUrl: "https://bestbuy.com/site/PRODUCT-NAME-EXACTLY/SKU#tabbed-customerreviews" },
  COSTCO: { name: "Costco", icon: "COSTCO", exampleUrl: "https://costco.com/PRODUCT-NAME-EXACTLY.product.SKU.html" },
  SAMSCLUB: { name: "Sam’s Club", icon: "SAMSCLUB", exampleUrl: "https://samsclub.com/p/PRODUCT-NAME-EXACTLY/UnitID" },
  TARGET: { name: "Target", icon: "TARGETCOM", exampleUrl: "https://target.com/p/PRODUCT-NAME-EXACTLY/-IDNumber?type=scroll_to_review_section#link=sametab" },
  WALMART: { name: "Walmart", icon: "WALMART", exampleUrl: "https://walmart.com/ip/PRODUCT-NAME-EXACTLY/UnitID" },
  GOOGLE_MAPS: { name: "Google Maps", icon: "GOOGLE", exampleUrl: "https://www.google.com/maps/place/LOCATION+NAME+EXACTLY/gpslat/gpslong/gpslat/traceIDforReviews" },
  GLASSDOOR: { name: "Glassdoor", icon: "GLASSDOOR", exampleUrl: "https://www.glassdoor.com/Reviews/COMPANY-NAME-Reviews-ID.html" },
  CONSUMER_AFFAIRS: { name: "Consumer Affairs", icon: "CONSUMERAFFAIRS", exampleUrl: "https://www.consumeraffairs.com/PRODUCT-NAME-EXACTLY" },
  G2_CROWD: { name: "G2 Crowd", icon: "G2", exampleUrl: "https://www.g2.com/PRODUCT-NAME-EXACTLY" },
  TRUSTPILOT: { name: "TrustPilot", icon: "TRUSTPILOT", exampleUrl: "https://www.trustpilot.com/PRODUCT-NAME-EXACTLY" }
};


export const validationSources = [
  {
    id: 1,
    title: SOURCES.AMAZON,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?amazon\.[a-z]{2,}\/?.*$/
  },
  {
    id: 2,
    title: SOURCES.REDDIT,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?reddit\.[a-z]{2,}\/?.*$/
  },
  {
    id: 3,
    title: SOURCES.BESTBUY,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?bestbuy\.[a-z]{2,}\/?.*$/
  },
  {
    id: 4,
    title: SOURCES.SAMSCLUB,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?samsclub\.[a-z]{2,}\/?.*$/
  },
  {
    id: 5,
    title: SOURCES.TARGET,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?target\.[a-z]{2,}\/?.*$/
  },
  {
    id: 6,
    title: SOURCES.WALMART,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?walmart\.[a-z]{2,}\/?.*$/
  },
  {
    id: 7,
    title:SOURCES.COSTCO,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?costco\.[a-z]{2,}\/?.*$/
  },
  {
    id: 8,
    title: SOURCES.CONSUMER_AFFAIRS,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?consumeraffairs\.[a-z]{2,}\/?.*$/
  },
  {
    id: 9,
    title: SOURCES.GOOGLE_MAPS,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?google\.[a-z]{2,}\/?.*$/
  },
  {
    id: 10,
    title: SOURCES.G2_CROWD,
    selected: false,
    validation: /^(https?:\/\/)?(www\.)?g2\.[a-z]{2,}\/?.*$/
  },
  {
    id: 11,
    title: SOURCES.TRUSTPILOT,
    selected: false,
    validation: /^(https?:\/\/)?([a-z]{2}\.)?(www\.)?trustpilot\.[a-z]{2,}\/?.*$/
  },
  {
    id: 12,
    title: SOURCES.GLASSDOOR,
    selected: true,
    validation: /^(https?:\/\/)?(www\.)?glassdoor\.[a-z]{2,}\/?.*$/
  }
];
