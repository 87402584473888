import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import SidePanelSection from '../../components/SidePanelSection';
import { Concept } from '../../classes/Concepts';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import { NoResultsMessage } from '../NoResultsMessage';
import { Colors } from '../../styles';
import Tooltip from '../../components/core/Tooltip';
import TextList from '../../components/TextList';
import { useFilter } from '../../search_params';
import { selectConcept } from '../../actions';
import { PlainTextButton } from '../../components/core/Button';
import PlaceholderText from '../../components/core/PlaceholderText';
import DetailsListRow from './DetailsListRow';
import { StoreContext } from '../../StoreContext';
import { getSentimentStatus, SENTIMENT_STATUS } from '../../utils/sentimentStatus';

export function SearchDetailsSection({
  details,
  documentCount,
  header,
  termCountWhileLoading: matchCountWhileLoading,
  tooltip,
  dataTestId,
  noLink,
  className,
  isAndTypeConcept,
}) {
  const matchCount = details?.concepts.length ?? matchCountWhileLoading;
  const hasMatches = matchCount > 0;

  if (isAndTypeConcept) {
    return (
      <SidePanelSection
        header={
          <>
            <span>{header}</span>
            <span
              css={css`
                  margin-left: auto;
                  font-weight: normal;
              `}
            >
            {details ? (
              <>
                {thousandify(details.total_count)}{' '}
                <span
                  css={css`
                      color: ${Colors.gray5};
                  `}
                >
                  ({percentify(details.total_count, documentCount)})
                </span>
              </>
            ) : (
              <PlaceholderText style={{ width: '5rem' }} />
            )}
          </span>
          </>
        }
        tooltip={tooltip}
        asList={hasMatches}
        dataTestId={dataTestId}
        className={className}
      >
        {hasMatches && details ? (
          <div css={css`
              display: flex;
              justify-content: space-between;
              font-size: 0.875rem;
              padding: 0.25rem;
          `}>
           <div>
             {details.concepts.map(concept => concept.name).join(' AND ')}
           </div>
            <div>
              {details.total_count}
            </div>
          </div>
        ) : hasMatches ? (
          [...Array(matchCountWhileLoading)].map((n, i) => {
            return <LoadingMatchTooltip key={i} />;
          })
        ) : (
          <NoResultsMessage>No {header.toLowerCase()} found</NoResultsMessage>
        )}
      </SidePanelSection>
    );
  }
  return (
    <SidePanelSection
      header={
        <>
          <span>{header}</span>
          <span
            css={css`
              margin-left: auto;
              font-weight: normal;
            `}
          >
            {details ? (
              <>
                {thousandify(details.total_count)}{' '}
                <span
                  css={css`
                    color: ${Colors.gray5};
                  `}
                >
                  ({percentify(details.total_count, documentCount)})
                </span>
              </>
            ) : (
              <PlaceholderText style={{ width: '5rem' }} />
            )}
          </span>
        </>
      }
      tooltip={tooltip}
      asList={hasMatches}
      dataTestId={dataTestId}
      className={className}
    >
      {hasMatches && details ? (
        _.orderBy(details.concepts, 'count', 'desc').map(concept => {
          return (
            <MatchTooltip
              key={concept.hash()}
              concept={concept}
              noLink={noLink}
            />
          );
        })
      ) : hasMatches ? (
        [...Array(matchCountWhileLoading)].map((n, i) => {
          return <LoadingMatchTooltip key={i} />;
        })
      ) : (
        <NoResultsMessage>No {header.toLowerCase()} found</NoResultsMessage>
      )}
    </SidePanelSection>
  );
}

SearchDetailsSection.propTypes = {
  documentCount: PropTypes.number.isRequired,
  details: PropTypes.shape({
    total_count: PropTypes.number.isRequired,
    concepts: PropTypes.arrayOf(PropTypes.instanceOf(Concept).isRequired)
  }),
  header: PropTypes.string.isRequired,
  termCountWhileLoading: PropTypes.number.isRequired,
  tooltip: PropTypes.node,
  dataTestId: PropTypes.string,
  noBorder: PropTypes.bool
};

export function TotalMatchesSection({
  activeSearch,
  totalCount,
  documentCount,
  footer,
  isLoading,
  dataTestId,
  className
}) {
  return (
    <div
      css={css`
        padding: 0.5rem;
        border-bottom: 1px solid ${Colors.gray2};
        font-size: 0.875rem;
      `}
      data-test-id={dataTestId}
      className={className}
    >
      <div
        css={css`
          display: flex;
        `}
      >
        <strong
          css={css`
            flex: 1;
          `}
        >
          Total matches
        </strong>
        {isLoading ? (
          <PlaceholderText style={{ width: '5rem' }} />
        ) : activeSearch ? (
          <span>
            {thousandify(totalCount)}{' '}
            <span
              css={css`
                color: ${Colors.gray5};
              `}
            >
              ({percentify(totalCount, documentCount)})
            </span>
          </span>
        ) : (
          <span
            css={css`
              color: ${Colors.gray5};
            `}
          >
            N/A
          </span>
        )}
      </div>
      {footer && (
        <div
          css={css`
            margin-top: 0.5rem;
          `}
        >
          {footer}
        </div>
      )}
    </div>
  );
}

TotalMatchesSection.propTypes = {
  activeSearch: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  documentCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  footer: PropTypes.node,
  dataTestId: PropTypes.string,
  className: PropTypes.string
};

function MatchTooltip({ concept, noLink }) {
  const { projectId } = useParams();
  const filter = useFilter();
  const { project } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY
  const anchor = (
    <PlainTextButton
      css={css`
        width: 100%;
        &:hover {
          background: ${Colors.blue0};
        }
        ${noLink &&
        `
          cursor: default;
          &:hover {
            background: none;
          }
        `}
      `}
      onClick={() => {
        !noLink && selectConcept(projectId, concept, filter, isSentimentReady);
      }}
    >
      <DetailsListRow left={concept.name} right={thousandify(concept.count)} />
    </PlainTextButton>
  );

  if (!concept.originalTexts) {
    return anchor;
  }

  const texts = concept.originalTexts.map(text => (
    <strong key={text}>“{text}”</strong>
  ));

  return (
    <Tooltip anchor={anchor} position="right">
      Conceptual match for <TextList items={texts} />
    </Tooltip>
  );
}

function LoadingMatchTooltip() {
  return (
    <DetailsListRow
      left={<PlaceholderText style={{ width: `${_.random(4, 7)}rem` }} />}
      right={<PlaceholderText style={{ width: '2rem' }} />}
    />
  );
}
