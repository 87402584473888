import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { css } from '@emotion/react';
import { Tooltip, message } from 'antd';

import { Button } from '../../components/core/Button';
import { Mixins } from '../../styles';
import { Icon, IconTypes } from '../../components/icons';

const DownloadConceptList = ({ sharedConceptLists, activeConcepts }) => {
  const hasSharedConcepts =
    sharedConceptLists &&
    sharedConceptLists.some(list => list?.concepts && list.concepts.length > 0);
  const hasActiveConcepts = activeConcepts && activeConcepts.length > 0;

  const canDownload = hasSharedConcepts || hasActiveConcepts;

  const handleDownload = () => {
    if (!canDownload) return;

    const workbook = XLSX.utils.book_new();
    const data = [['Concept Label', 'HEX Code', 'All Included Concepts','Shared Concept List Name']];

    if (hasSharedConcepts) {
      const uniqueConcepts = new Map();

      sharedConceptLists.forEach(({ name: listName, concepts }) => {
        concepts.forEach(({ name, color, texts }) => {
          const uniqueKey = `${name}-${color}-${texts.join(',')}-${listName}`;
          if (!uniqueConcepts.has(uniqueKey)) {
            uniqueConcepts.set(uniqueKey, {  name, color, texts, listName });
          }
        });
      });

      Array.from(uniqueConcepts.values()).forEach(({ name, color, texts, listName }) => {
        const formattedTexts = texts.map(text => `"${text}"`).join(' OR ');
        data.push([name, color, formattedTexts,listName]);
      });
    } else {
      const uniqueActiveConcepts = new Map();

      activeConcepts.forEach(({ name, color, texts }) => {
        const uniqueKey = `${name}-${color}-${texts.join(',')}`;
        if (!uniqueActiveConcepts.has(uniqueKey)) {
          uniqueActiveConcepts.set(uniqueKey, { name, color, texts });
        }
      });

      Array.from(uniqueActiveConcepts.values()).forEach(({ name, color, texts }) => {
        const formattedTexts = texts.map(text => `"${text}"`).join(' OR ');
        data.push(['N/A', name, color, formattedTexts]);
      });
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Concept Lists');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Concept_Lists.xlsx');

    message.success('Concept List downloaded successfully!', 1);
  };


  return (
    <Tooltip title={<div style={{ color: 'black' }}>Download Concept List</div>} color={'white'} placement="top">
      <Button
        css={css`
          ${Mixins.ellipsify}
          display: flex;
          gap: 10px;
          &:disabled {
            pointer-events: none;
          }
        `}
        onClick={handleDownload}
        disabled={!canDownload}
        data-tracking-item="download-share-pane_download-xlsx-button"
      >
        Download Concept List
        <Icon type={IconTypes.DOWNLOAD} css={css`
        color: #2c4249!important`} />
      </Button>
    </Tooltip>
  );
};

export default DownloadConceptList;
