import React from 'react';
import styled from '@emotion/styled';

import StreamCategory from '../StreamCategory/StreamCategory';
import { SOURCE_CATEGORIES, SOURCES } from '../constants';


const SidebarList = ({ handleAddSource }) => {
  return (
    <Container>
      <StreamCategory
        title={SOURCE_CATEGORIES.SOCIAL_MEDIA.title}
        sources={[
          SOURCES.AMAZON,
          SOURCES.BESTBUY,
          SOURCES.CONSUMER_AFFAIRS,
          SOURCES.COSTCO,
          SOURCES.G2_CROWD,
          SOURCES.GLASSDOOR,
          SOURCES.GOOGLE_MAPS,
          SOURCES.REDDIT,
          SOURCES.SAMSCLUB,
          SOURCES.TARGET,
          SOURCES.TRUSTPILOT,
          SOURCES.WALMART
        ]}
        handleAddSource={handleAddSource}
      />
    </Container>
  );
};

export default SidebarList;

const Container = styled.div``;
