import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { loadSharedConceptLists } from '../actions';
import { StoreContext } from '../StoreContext';
import { ActiveConcepts } from './active_concepts/ActiveConcepts';
import { TabPanel } from './Tabs';
import { NewListButton } from './active_concepts/NewListButton';
import { objHasPerm } from '../utils/common';

import { Button } from '../components/core/Button';
import Spinner from '../components/core/Spinner';
import { chatbotThemes } from '../utils/ApiUtilsV5';
import { restoreSharedConceptList } from '../utils/ApiUtilsV5';
import { useCurrentView } from './views/view';
import { useSearchParams } from '../search_params';
import { Concept } from '../classes/Concepts';
import { getNewConceptColor } from '../components/Concept';
import { FeatureFlagsContext } from '../FeatureFlagsContext';

function AddActiveConceptsFromAIButton() {
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  const { topConcepts, activeConcepts } = React.useContext(StoreContext);
  const [disabled, setDisabled] = React.useState(false);

  function conceptFromString(conceptString) {
    return (
      topConcepts.find(concept => concept.toString() == conceptString) ||
      Concept.fromString(conceptString)
    );
  }

  function colorConcept(concept, activeConcepts) {
    const color = concept.color || getNewConceptColor(activeConcepts);
    concept.color = color;
    return concept;
  }

  function getColoredConcepts(concepts) {
    const processed = [];

    for (var concept of concepts) {
      const coloredConcept = colorConcept(
        concept,
        activeConcepts.concat(processed)
      );
      processed.push(coloredConcept);
    }

    return processed;
  }

  function createConceptList(name, concepts) {
    // note:
    // the ApiUtils function is called restoreSharedConceptList, but really, it
    // calls the put concept_lists endpoint to create a concept list with the
    // specified concepts. so we can use it here.
    restoreSharedConceptList(projectId, name, concepts, true).then(result => {
      console.log('result');
      console.log(result);
    });
  }

  function createPerThemeList(data) {
    for (var entry of data) {
      // const conceptTexts = entry.words;
      // const concepts = conceptTexts.map(conceptFromString);
      // const concepts = entry.concepts.map(
      //   c => ({
      //     ...conceptFromString(c.texts),
      //     name: c.name
      //   })
      // );
      const concepts = entry.concepts.map(c => {
        const concept = Concept.fromJSON({
          texts: c.texts,
          name: c.name,
          type: c.texts.length > 1 && 'and'
        });
        // force name
        concept.name = c.name;
        return concept;
      });
      const coloredConcepts = getColoredConcepts(concepts);

      createConceptList(entry.theme, coloredConcepts);
      console.log('created concept list', entry.theme);
    }
  }

  /*
    create a list that has one concept per theme.
    the name of the list is "helios_themes".
    
    each concept in the corresponds to one theme, and contains
    the texts of all the words in the theme.
   */
  function createCombinedList(data) {
    const concepts = [];
    for (var entry of data) {
      // concepts.push({ name: entry.theme, texts: entry.words });
      // concepts.push({ name: entry.theme, texts: [...entry.concepts.map(c=>c.texts)] });
      const concept = { name: entry.theme };
      const texts = [];
      for (let c of entry.concepts) {
        texts.push(...c.texts);
      }
      concept.texts = texts;

      concepts.push(concept);
    }
    const coloredConcepts = getColoredConcepts(concepts);

    createConceptList('Helios Themes', coloredConcepts);
    console.log('created combined concept list');
  }

  const createAiList = () => {
    setDisabled(true);

    console.log('Creating concept lists from AI...');

    chatbotThemes(projectId, prompt).then(response => {
      console.log('response from endpoint');
      console.log(response);
      const themeData = response;

      // create per-theme-lists
      console.log('creating per theme lists...');
      createPerThemeList(themeData);
      console.log('creating per theme lists... done.');

      // create combined-list
      console.log('creating combined list...');
      createCombinedList(themeData);
      console.log('creating combined list... done.');
      console.log('Creating concept lists from AI... done.');
      setDisabled(false);
      alert('please refresh the page to see updated concept lists.');
      window.location.reload();
    });
  };

  return disabled ? (
    <Button disabled={true}>
      <Spinner />
      Creating lists...
    </Button>
  ) : (
    <Button onClick={createAiList} palette="green">
      ✨ Add with Helios
    </Button>
  );
}

export function ActiveConceptsPanel() {
  const { projectId } = useParams();
  const {
    activeConceptListName,
    project,
    activeConcepts,
    isOpeningConceptList,
    sharedConceptLists
  } = useContext(StoreContext);
  const featureFlags = React.useContext(FeatureFlagsContext);

  function refetchConceptLists() {
    loadSharedConceptLists(projectId);
  }
  const hasWritePermissions = objHasPerm(project, 'write');

  return (
    <TabPanel
      header={
        <>
          Active concepts
          {featureFlags.helios_features && hasWritePermissions && (
            <AddActiveConceptsFromAIButton projectId={projectId} />
          )}
          {hasWritePermissions && <NewListButton />}
        </>
      }
      body={
        <ActiveConcepts
          projectId={projectId}
          permissions={project.permissions}
          activeConcepts={activeConcepts}
          isOpeningConceptList={isOpeningConceptList}
          activeConceptListName={activeConceptListName}
          conceptLists={sharedConceptLists}
          refetchConceptLists={refetchConceptLists}
        />
      }
    />
  );
}
