import React from 'react';
import styled from '@emotion/styled';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { StoreContext } from '../../../StoreContext';
import UploadStreamDataActions from '../UploadStreamDataActions';
import { SOURCES } from '../constants';
import { Button } from '../../../components/core/Button';


const SelectedSources = ({
                           selectedSources,
                           handleRemove,
                           handleInputChange,
                           handleCreateProject,
                           sendEmail,
                           setSendEmail,
                           sentiment,
                           setSentiment,
                           repeat,
                           setRepeat,
                           repeatInterval,
                           setRepeatInterval,
                           isLoading
                         }) => {
  const { username } = React.useContext(StoreContext);

  return (
    <Container>
      <Scrollbars style={{ height: "49vh" }} autoHide>
        {selectedSources.length > 0 ? (
          selectedSources.map((source) => {
            const sourceInfo = Object.values(SOURCES).find(
              (s) => s.name === source.name
            );

            const placeholderUrl = sourceInfo?.exampleUrl
              ? `${sourceInfo.exampleUrl}`
              : "Enter URL";

            return (
              <SourceItem key={source.id}>
                <InputContainer>
                  <InputField
                    type="text"
                    placeholder={source.url ? "" : placeholderUrl}
                    value={source.url || ""}
                    onChange={(e) => handleInputChange(source.id, e.target.value)}
                    style={{ borderColor: source.error ? "red" : "#ccc" }}
                    disabled={isLoading}
                  />
                  <ErrorText>{source.error || " "}</ErrorText>
                </InputContainer>
                <ButtonWrapper>
                  <RemoveButton  disabled={isLoading} onClick={() => handleRemove(source.id)}>✖</RemoveButton>
                </ButtonWrapper>
              </SourceItem>
            );
          })
        ) : (
          <EmptyState>No sources selected for this group</EmptyState>
        )}
      </Scrollbars>

      <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
        <UploadStreamDataActions
          username={username}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          sentiment={sentiment}
          setSentiment={setSentiment}
          repeat={repeat}
          setRepeat={setRepeat}
          repeatInterval={repeatInterval}
          setRepeatInterval={setRepeatInterval}
        />

        <div>
          <Button onClick={handleCreateProject} disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Project"}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default SelectedSources;

const Container = styled.div`
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    height: 56vh;
    background-color: #f9f9f9;
`;

const SourceItem = styled.div`
    display: grid;
    grid-template-columns: 8fr 60px;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }
`;


const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    min-height: 50px;
`;

const InputField = styled.input`
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
`;

const RemoveButton = styled.button`
    background-color: #ff4b4b;
    color: white;
    border: none;
    font-size: 14px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s;

    &:hover {
        background-color: #d43f3f;
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.95);
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    min-height: 50px;
    justify-content: center;
`;

const EmptyState = styled.div`
    text-align: center;
    color: #777;
    font-size: 14px;
    margin-top: 10px;
`;

const ErrorText = styled.div`
    color: red;
    font-size: 12px;
    min-height: 16px;
    margin-top: 2px;
`;

