import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import * as gtm from '../../utils/gtm';
import { fetchDriversAndSentiments, selectConcept } from '../../actions';
import ConceptComponent from '../Concept';
import List from './List';
import VirtualList from '../VirtualList';
import { Colors } from '../../styles';
import PlaceholderText from './PlaceholderText';
import { useFilter, useSearchParams } from '../../search_params';
import { percentify, thousandify } from '../../utils/NumFmtUtils';
import { useHover } from '../../HoverContext';
import { StoreContext } from '../../StoreContext';
import { useCurrentFeature, usePrevious } from '../../utils/hooks';
import { Concept } from '../../classes/Concepts';
import { Icon, IconTypes } from '../icons';
import {
  getSentimentStatus,
  SENTIMENT_STATUS
} from '../../utils/sentimentStatus';
import Tooltip from './Tooltip';
import { TooltipRow } from '../TooltipRow';
import { useTotalMatches } from '../../utils/hooks/useTotalMatches';
import TooltipConceptContent from '../TooltipConceptContent';
import { getFeatureFlags } from '../../featureFlagsSingleton';

const TableContext = React.createContext({
  sortedColumn: undefined,
  sortDirection: undefined,
  onSortChange: () => {}
});

export function Table({ children, onSortChange, sortDirection, sortedColumn }) {
  return (
    <TableContext.Provider
      value={{ onSortChange, sortDirection, sortedColumn }}
    >
      {children}
    </TableContext.Provider>
  );
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
  onSortChange: PropTypes.func,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  sortedColumn: PropTypes.string
};

export function Header({ children, borderColor = 'transparent' }) {
  return (
    <div
      data-test-id="header"
      css={css`
        font-weight: 700;
        border-bottom: 1px solid ${borderColor};
        background: white;
      `}
    >
      {children}
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired
};

export const Body = React.forwardRef(function Body(
  { children, hoverable = true },
  ref
) {
  return (
    <div data-test-id="body" ref={ref}>
      {typeof children === 'function' ? (
        <VirtualList>{children}</VirtualList>
      ) : (
        <List bordered={false} scrollable={false} hoverable={hoverable}>
          {children}
        </List>
      )}
    </div>
  );
});

Body.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  hoverable: PropTypes.bool
};

export const Row = React.forwardRef(function Row(
  {
    children,
    onClick,
    active,
    renderExpanded,
    bordered,
    height,
    className,
    background,
    color,
    ...props
  },
  ref
) {
  const { searchParams } = useSearchParams();
  const isShowPrevalence = searchParams.show_prevalence_rank === 'true';
  const clickable = !active && onClick;
  const borderThickness = '0.125rem';
  return (
    <div
      data-test-id="row"
      css={css`
        background: ${background};
        position: relative;

        ${bordered &&
        css`
          &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            border: ${borderThickness} solid ${color ? color : Colors.blue4};
          }
        `}
      `}
      {...props}
      ref={ref}
    >
      <div
        css={css`
          padding-left: 0.5rem;
          display: grid;
          grid-template-columns: ${isShowPrevalence ? '20% 20% 20% auto' : '30% 20% auto'};
          height: ${height};
          ${clickable &&
          css`
            cursor: pointer;
          `}
        `}
        className={className}
        {...(clickable && {
          tabIndex: 0,
          role: 'button',
          onClick,
          onKeyPress: event => {
            if (event.key === 'Enter' || event.key === ' ') {
              onClick();
            }
          }
        })}
      >
        {children}
      </div>
      {renderExpanded?.()}
    </div>
  );
});

Row.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  renderExpanded: PropTypes.func,
  bordered: PropTypes.bool,
  height: PropTypes.string,
  className: PropTypes.any
};

Row.defaultProps = {
  height: '2.5rem'
};

export const Cell = React.forwardRef(function Cell(
  { onClick, loading, children, className, tooltipData, isOutlier, index },
  ref
) {
  const content = (
    <div
      className={className}
      ref={ref}
      data-test-id="cell"
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        ${loading &&
        css`
          color: ${Colors.gray5};
        `}

        ${onClick &&
        css`
          cursor: pointer;
          user-select: none;
        `}
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );

  return isOutlier ? (
    <Tooltip
      tooltipWidth="100%"
      position={index === 0 ? 'below' : 'above'}
      anchor={content}
    >
      <div className="drivers__tooltip">
        <TooltipRow header="Concept" value={tooltipData.concept.name} />
        <TooltipRow header="Matches in Outlier documents" value={tooltipData.outlierMatches} />
        <TooltipRow header="% of Outlier documents" value={tooltipData.outlierPercentage} />
        <TooltipRow header={`${tooltipData.isTotal ? 'Total' : 'Exact'} matches in current documents`}
                    value={tooltipData.totalMatches} />
      </div>
    </Tooltip>
  ) : content;
});

Cell.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltipData: PropTypes.object,
  isOutlier: PropTypes.bool,
  index: PropTypes.number
};


function flipDirection(direction) {
  return direction === 'asc' ? 'desc' : 'asc';
}

export function TableSortControl({
  sortingKey,
  invertedSort = false,
  children
}) {
  const { sortDirection, sortedColumn, onSortChange } =
    useContext(TableContext);

  return (
    <button
      type="button"
      onClick={() => {
        onSortChange(
          sortingKey,
          sortingKey === sortedColumn
            ? flipDirection(sortDirection)
            : invertedSort
              ? 'asc'
              : 'desc'
        );
      }}
      css={css`
        display: inline-flex;
        flex-direction: row;
        white-space: pre-wrap;
        gap: 0.5rem;
      `}
      data-tracking-item="concept-list-table_sortable-header"
    >
      <SortOrderIndicator
        sortingKey={sortingKey}
        css={css`
          flex-shrink: 0;
          align-self: center;
        `}
      />
      <span
        css={css`
          flex: 1;
          text-align: start;
        `}
      >
        {children}
      </span>
    </button>
  );
}

TableSortControl.propTypes = {
  children: PropTypes.node.isRequired,
  sortingKey: PropTypes.string.isRequired,
  invertedSort: PropTypes.bool
};

function SortOrderIndicator({ sortingKey, className }) {
  const { sortDirection, sortedColumn } = useContext(TableContext);
  const arrowDirection = sortDirection === 'asc' ? 'up' : 'down';

  return (
    <Icon
      type={IconTypes.SORT}
      direction={sortingKey === sortedColumn ? arrowDirection : undefined}
      className={className}
    />
  );
}

export function LoadingTableBody({ displayPlaceholderText }) {
  return (
    <Body hoverable={false}>
      {[...Array(50)].map((element, index) => {
        return (
          <LoadingRow
            key={index}
            displayPlaceholderText={displayPlaceholderText}
          />
        );
      })}
    </Body>
  );
}

export function LoadingRow({ displayPlaceholderText, active }) {
  return (
    <Row active={active}>
      {displayPlaceholderText ? <LoadingRowContent /> : null}
    </Row>
  );
}

LoadingRow.propTypes = {
  displayPlaceholderText: PropTypes.bool,
  active: PropTypes.bool
};

function LoadingRowContent() {
  const firstCellWidth = useRef(`${_.random(5, 10)}rem`);
  const secondCellWidth = useRef(`${_.random(2.5, 3, true)}rem`);

  return (
    <>
      <Cell>
        <PlaceholderText style={{ width: firstCellWidth.current }} />
      </Cell>
      <Cell>
        <PlaceholderText style={{ width: secondCellWidth.current }} />
      </Cell>
    </>
  );
}

export const ScrollableTable = React.forwardRef(function ScrollableTable(
  { header, body, ...props },
  ref
) {
  const scrollContainerRef = useRef();
  const tableBodyRef = useRef();

  useImperativeHandle(ref, () => ({
    scrollToRow: rowRef => {
      const row = rowRef.current;

      if (row) {
        const { top, bottom } = row.getBoundingClientRect();
        const table = scrollContainerRef.current.getBoundingClientRect();

        const partiallyOutsideTable = top < table.top || bottom > table.bottom;
        const partiallyBelowTable = top < table.bottom && bottom > table.bottom;

        if (partiallyOutsideTable) {
          // If row is partially visible and at the bottom of the table,
          // don't scroll all the way to the top
          row.scrollIntoView(!partiallyBelowTable);
        }
      }
    }
  }));

  return (
    <Table {...props}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
      >
        <div
          id="print"
          data-type="transparent"
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {header}
          <div
            ref={scrollContainerRef}
            css={css`
              flex: 1;
              min-height: 0;
              overflow: auto;
              &::-webkit-scrollbar {
                display: none;
              }
            `}
          >
            <div ref={tableBodyRef}>{body}</div>
          </div>
        </div>
      </div>
    </Table>
  );
});

ScrollableTable.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  selectedRowRef: PropTypes.object
};

export function ConceptHeader({
  visualizationHeader,
  loading = false,
  isShowPrevalence,
  matchType
}) {
  return (
    <Header borderColor={Colors.gray2}>
      <Row>
        <HeaderCell loading={loading} sortingKey="name" invertedSort>
          Concepts
        </HeaderCell>

        {isShowPrevalence &&
          <HeaderCell loading={loading} sortingKey="prevalence" invertedSort>
          Prevalence Rank
         </HeaderCell>
        }

        <HeaderCell loading={loading} sortingKey="matches">
          {matchType === 'exact' ? 'Exact matches' : 'Total matches'}
        </HeaderCell>

        {visualizationHeader && <Cell>{visualizationHeader}</Cell>}
      </Row>
    </Header>
  );
}

ConceptHeader.propTypes = {
  visualizationHeader: PropTypes.node,
  loading: PropTypes.bool,
  matchType: PropTypes.oneOf(['exact', 'total']).isRequired
};

export function HeaderCell({ children, loading, sortingKey, invertedSort }) {
  return (
    <Cell loading={loading}>
      {!loading ? (
        <TableSortControl sortingKey={sortingKey} invertedSort={invertedSort}>
          {children}
        </TableSortControl>
      ) : (
        children
      )}
    </Cell>
  );
}

HeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  sortingKey: PropTypes.string.isRequired,
  invertedSort: PropTypes.bool
};

export const ConceptRow = React.forwardRef(function ConceptRow(
  {
    projectId,
    concept,
    renderVisualization,
    renderExpanded,
    selected,
    matchCount,
    totalCount,
    filterCount,
    totalMatches,
    color,
    index,
    isShowPrevalence
  },
  ref
) {
  const { searchParams } = useSearchParams();
  const isTotalMatches = useTotalMatches();
  const feature = useCurrentFeature();
  const isSentiment = feature === 'sentiment'
  const activeInclude = searchParams.outlier_mode === 'include';
  const percentageByTotal = searchParams.count_percentage === 'total';
  const isOutlierConcept = concept.outlier;
  const background =
    activeInclude && isOutlierConcept ? '#89adbb2b' : 'inherit';
  const filter = useFilter();
  const [, setHovered] = useHover();

  const { project, selectedConcept, tooltipLoading } = useContext(StoreContext);
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;
  const matchType = searchParams.match_type;
  const [isOpenConceptTooltip, setIsOpenConceptTooltip] = useState(true);
  const tooltipData = useMemo(
    () => ({
      concept,
      isTotal: isSentiment ? false: isTotalMatches,
      outlierMatches: matchCount,
      outlierPercentage: percentify(matchCount, filterCount),
      totalMatches
    }),
    [totalMatches, filterCount, matchCount]
  );
  let transformedArray = null;
  if (selected) {
    transformedArray = concept.texts.map((item) => ({
      texts: [item],
    }));
  }

  useFetchDriversAndSentiments(
    projectId,
    {
      type: 'specified',
      concepts: transformedArray
    },
    filter,
    null,
    matchType,
    [selected],
    isSentimentReady,
    searchParams?.drivers_of
  );

  const tooltipContent = (
    <TooltipConceptContent
      isOpen={isOpenConceptTooltip}
      setIsOpen={setIsOpenConceptTooltip}
      concept={concept}
      tooltipLoading={tooltipLoading}
      selectedConcept={selectedConcept}
    />
  )

  return (
    <Row
      onClick={
        !selected
          ? () => {
              selectConcept(projectId, concept, filter, isSentimentReady);
              gtm.trigger('concept-list-table_selectable-concept-row');
            }
          : undefined
      }
      renderExpanded={renderExpanded}
      active={selected}
      bordered={selected}
      ref={ref}
      color={color}
      background={background}
      onMouseEnter={() => setHovered(concept)}
      onMouseLeave={() => setHovered()}
    >
      <Cell
        tooltipData={tooltipData}
        isOutlier={isOutlierConcept}
        index={index}
      >
        <ConceptComponent
          concept={concept}
          stopPropagation
          trackingPrefix="concept-list-table"
        />
      </Cell>
      {isShowPrevalence &&
        <Cell
          tooltipData={tooltipData}
          isOutlier={isOutlierConcept}
          index={index}
        >
          {concept.prevalence}
        </Cell>
      }
      <Cell
        tooltipData={tooltipData}
        isOutlier={isOutlierConcept}
        index={index}
      >

        <Tooltip
          position={index > 2 ? 'above' : 'right'}
          anchor={
            <span>
          {thousandify(matchCount)}{' '}
              <span
                css={css`
                    color: ${Colors.gray5};
                `}
              >
            (
                {percentageByTotal
                  ? percentify(matchCount, totalCount)
                  : percentify(matchCount, filterCount)}
                )
          </span>
        </span>
          }
          visible={selected && isOpenConceptTooltip && getFeatureFlags().concept_pop_up}

          contentHoverable={false}
        >
          {tooltipContent}
        </Tooltip>

      </Cell>
      <Cell>{renderVisualization()}</Cell>
    </Row>
  );
});

ConceptRow.propTypes = {
  projectId: PropTypes.string.isRequired,
  concept: PropTypes.shape({
    sharedConceptId: PropTypes.string,
    exactTermIds: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  renderVisualization: PropTypes.func.isRequired,
  renderExpanded: PropTypes.func,
  selected: PropTypes.bool,
  matchCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  filterCount: PropTypes.number,
  totalMatches: PropTypes.number,
  color: PropTypes.string,
  index: PropTypes.number,
};

export function useScrollToSelectedConceptRow(tableRef, selectedRowRef) {
  const { selection } = useContext(StoreContext);
  const prevSelection = usePrevious(selection);

  // Scroll to the selected row if the selected concept has changed. i.e.:
  // * There wasn't a selection, but now there is
  // * The selected concept's texts changed (unless it's an active concept that
  //   was edited)
  useEffect(() => {
    if (!selection || selection === prevSelection) {
      return;
    }

    const textsChanged = !Concept.areTextsEqual(selection, prevSelection);
    const sameId =
      selection.isActive &&
      selection.sharedConceptId === prevSelection?.sharedConceptId;

    if (!prevSelection || (textsChanged && !sameId)) {
      tableRef.current.scrollToRow(selectedRowRef);
    }
  }, [selection, prevSelection]);
}


export function useFetchDriversAndSentiments(projectId, conceptSelector, filter = [], scoreField, matchType = 'both', selectedConcept , isSentimentReady = false, driversField = null) {
  useEffect(() => {
    if (projectId && conceptSelector && conceptSelector.concepts?.length) {
      (async () => {
        await fetchDriversAndSentiments(projectId, conceptSelector, filter, scoreField, matchType, isSentimentReady, driversField);
      })();
    }
  }, selectedConcept);
}
