import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';

export const AIAssistantButton = styled(Fab)`
  position: fixed;
  width: 41px;
  height: 41px;
  bottom: 20px;
  right: 18px;
  color: #fff;
`;
