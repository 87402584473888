import _ from 'lodash';
import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Icon } from '../icons/Icon';
import { FEATURES } from '../icons/types';
import { Colors } from '../../styles';
import buildRoutePath from '../../utils/buildRoutePath';
import Tooltip from '../core/Tooltip';
import { encodeFilter, stringify, useSearchParams } from '../../search_params';
import { FeatureFlagsContext } from '../../FeatureFlagsContext';

/**
 * @param {string} icon - Icon type
 * @param {string} routePattern - Route pattern to use for link
 * @returns {JSX.Element}
 */
export function NavigationLink({ icon, routePattern, label }) {
  const params = useParams();
  const { projectId } = params;
  const isDashboard = label === 'Dashboard';
  const featureFlags = useContext(FeatureFlagsContext);
  const path = buildRoutePath(routePattern, params);
  const lumiToken = localStorage.getItem('auth_token')?.replace(/['"]+/g, '') || null;

  const active = useRouteMatch({ path, exact: true });
  const { searchParams } = useSearchParams();
  const paramsToPreserve = [
    'search',
    'filter',
    'concepts',
    'drivers_of',
    'drivers_count',
    'prevalent_count'
  ];
  const subqueryToPreserve = _.pick(searchParams, paramsToPreserve);
  subqueryToPreserve.filter = encodeFilter(subqueryToPreserve.filter);
  const search = stringify(subqueryToPreserve);

  const openDashboard = () => {
    const defaultLinkDashboard = 'https://dashboards.luminoso.com';
    const dashboardUrl = `${process.env.DASHBOARDS_URL || defaultLinkDashboard}/app/projects/${projectId}/dashboard?lumitoken=${lumiToken}`;
    window.open(dashboardUrl, '_blank');
  };

  let linkElement;
  if (isDashboard && lumiToken) {
    // Use an <a> tag for external Dashboard link
    linkElement = (
      <LinkButton
        viewDashboard={featureFlags.less_clicks_btn}
        onClick={openDashboard}
        rel="noopener noreferrer"
        aria-label={`Go to ${label}`}
      >
        <Indicator />
        <Container>
          <Icon type={icon} />
        </Container>
      </LinkButton>
    );
  } else {
    // Use IconLink for internal navigation
    linkElement = (
      <>
        <IconLink
          to={{ pathname: path, search }}
          active={active}
          aria-label={`Go to ${label}`}
        >
          <Indicator />
          <Container>
            <Icon type={icon} />
          </Container>
        </IconLink>
      </>

    );
  }

  if (isDashboard) {
    return featureFlags.less_clicks_btn ? (
      <>
        <Tooltip position="right" anchor={linkElement}>
          {label}
        </Tooltip>
      </>
    ) : (
      <div></div>
    );
  } else {
    return (
      <>
        <Tooltip position="right" anchor={linkElement}>
          {label}
        </Tooltip>
      </>
    );
  }
}

NavigationLink.propTypes = {
  icon: PropTypes.oneOf(FEATURES).isRequired,
  routePattern: PropTypes.string.isRequired
};

const Indicator = styled.div`
    height: 100%;
    width: 0.3rem;
    border-radius: 0 3rem 3rem 0;
`;

const Container = styled.div`
    display: grid;
    width: 3rem;
    height: 3rem;
    place-content: center;
    place-items: center;
    background: transparent;
    border-radius: 0.25rem;
`;

const IconLink = styled(Link)`
    padding: 0;
    display: grid;
    grid-template-columns: 0.5rem auto;
    justify-content: space-between;
    gap: 0.5rem;
    width: 3.75rem;
    height: 3rem;
    transition: all ease-in-out 100ms;

    ${Indicator} {
        background: ${props => (props.active ? Colors.blue5 : 'transparent')};
    }

    ${Container} {
        color: ${props => (props.active ? Colors.blue7 : Colors.gray9)};
        background: ${props => (props.active ? Colors.blue1 : 'transparent')};
    }

    &:hover {
        ${Container} {
            color: ${Colors.blue7};
            background: ${props => (props.active ? Colors.blue1 : Colors.blue0)};
        }
    }
`;

const LinkButton = styled.a`
    padding: 0;
    display: ${props => (props.viewDashboard ? 'grid' : 'none')};
    grid-template-columns: 0.5rem auto;
    justify-content: space-between;
    gap: 0.5rem;
    width: 3.75rem;
    height: 3rem;
    transition: all ease-in-out 100ms;

    ${Indicator} {
        background: ${props => (props.active ? Colors.blue5 : 'transparent')};
    }

    ${Container} {
        color: ${props => (props.active ? Colors.blue7 : Colors.gray9)};
        background: ${props => (props.active ? Colors.blue1 : 'transparent')};
    }

    &:hover {
        ${Container} {
            color: ${Colors.blue7};
            background: ${props => (props.active ? Colors.blue1 : Colors.blue0)};
        }
    }
`;
