import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { MostPrevalentConceptsCard } from './highlights/MostPrevalentConcepts';
import { WhatPeopleFeelStronglyAboutCard } from './highlights/WhatPeopleFeelStronglyAbout';
import { ConversationClustersCard } from './highlights/ConversationClusters';
import { IssuesAffectingScoreCard } from './highlights/IssuesAffectingScore';
import { ProjectDetail } from './components/ProjectDetail';
import { FlexibleGrid } from './components/core/Layout';
import { Colors } from './styles';
import { ProjectTitle } from './highlights/ProjectTitle';
import { trademark } from './utils/trademark';
import { ToolCard } from './highlights/Cards';
import {
  chatbotGenerate,
  getHeliosSummary,
  postHeliosSummaryBuild
} from './utils/ApiUtilsV5';
import { Button } from './components/core/Button';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import { useFetch } from './utils/hooks';
import Spinner from './components/core/Spinner';

function HeliosSummary({ projectId }) {
  const [summary, setSummary] = useState();
  const [summaryStatus, setSummaryStatus] = useState();
  const { status, response } = useFetch(getHeliosSummary, projectId);

  const buildSummary = () => {
    postHeliosSummaryBuild(projectId);
    setSummaryStatus('building');
  };

  const updateSummaryBuildStatus = () => {
    getHeliosSummary(projectId).then(res => {
      if (res.status) {
        setSummaryStatus(res.status);
        setSummary(res.summary);
      } else {
        setSummaryStatus('no_exist');
      }
    });
  };

  useEffect(() => {
    if (projectId) {
      const checkSummaryBuildStatusTimer = setInterval(() => {
        if (summaryStatus === 'building') {
          updateSummaryBuildStatus();
        } else {
          clearInterval(checkSummaryBuildStatusTimer);
        }
      }, 4000);

      return () => {
        clearInterval(checkSummaryBuildStatusTimer);
      };
    }
  }, [summaryStatus, projectId]);

  useEffect(() => {
    setSummaryStatus(response?.status);
    setSummary(response?.summary);
  }, [response]);

  return (
    <ToolCard
      header="✨ Helios Summary"
      visualization={
        status !== 'FULFILLED' ? (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
            <Spinner /> Fetching summary data...
          </div>
        ) : summaryStatus == 'ready' ? (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              overflowY: 'auto',
              width: '100%',
              height: '100%'
            }}
          >
            <div>{summary}</div>
          </div>
        ) : summaryStatus == 'building' ? (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
            <Spinner /> Building summary data...
          </div>
        ) : summaryStatus == 'error' ? (
          <div>
            <div>
              Summary build attempt failed. Please contact Luminoso support for
              assistance, or click the button below to retry summary build.
            </div>
            <Button onClick={buildSummary}> Generate Summary </Button>
          </div>
        ) : (
          <div>
            <div>
              No summary data found. Click the button below to generate summary
              data.
            </div>
            <Button onClick={buildSummary}> Generate Summary </Button>
          </div>
        )
      }
      footer={<div></div>}
    />
  );
}

export function Highlights({ projectId, workspaceId }) {
  const featureFlags = React.useContext(FeatureFlagsContext);

  return (
    <div
      css={css`
        box-sizing: border-box;
        height: 100%;
        padding: 1rem;
        background: ${Colors.gray0};
        overflow-y: auto;

        > * {
          margin-top: 1rem;
        }
      `}
    >
      <ProjectTitle />
      <FlexibleGrid minColumnWidth="25rem" gap="medium">
        <MostPrevalentConceptsCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <ConversationClustersCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <IssuesAffectingScoreCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        <WhatPeopleFeelStronglyAboutCard
          projectId={projectId}
          workspaceId={workspaceId}
        />
        {featureFlags.helios_features && (
          <HeliosSummary projectId={projectId} />
        )}
      </FlexibleGrid>
      <footer
        css={css`
          text-align: center;
        `}
      >
        <ProjectDetail />
        {trademark}
      </footer>
    </div>
  );
}

Highlights.propTypes = {
  projectId: PropTypes.string.isRequired,
  workspaceId: PropTypes.string.isRequired
};
