import {
  CategoricalField,
  ScoreField,
  DateField
} from '../../classes/MetadataFields';

export const metadata = [
  new CategoricalField('ABV', [
    { value: '10', count: 351 },
    { value: '10.5', count: 763 },
    { value: '11', count: 125 },
    { value: '11.5', count: 40 },
    { value: '12', count: 1107 },
    { value: '12.5', count: 22 },
    { value: '13', count: 54 },
    { value: '14', count: 7 },
    { value: '15', count: 15 },
    { value: '16', count: 8 },
    { value: '3', count: 4 },
    { value: '3.5', count: 606 },
    { value: '4', count: 160 },
    { value: '4.5', count: 269 },
    { value: '5', count: 893 },
    { value: '5.5', count: 655 },
    { value: '6', count: 350 },
    { value: '6.5', count: 1155 },
    { value: '7', count: 573 },
    { value: '7.5', count: 210 },
    { value: '8', count: 314 },
    { value: '8.5', count: 427 },
    { value: '9', count: 428 },
    { value: '9.5', count: 201 },
    { value: '9.9', count: 16 },
    { value: '<3.0', count: 17 },
    { value: '>=19.0', count: 7 }
  ]),
  new ScoreField('Appearance score', 1, 5, [
    { value: 1, count: 29 },
    { value: 1.5, count: 69 },
    { value: 2, count: 287 },
    { value: 2.5, count: 403 },
    { value: 3, count: 1134 },
    { value: 3.5, count: 1858 },
    { value: 4, count: 3399 },
    { value: 4.5, count: 1319 },
    { value: 5, count: 279 }
  ]),
  new ScoreField('Aroma score', 1, 5, [
    { value: 1, count: 66 },
    { value: 1.5, count: 120 },
    { value: 2, count: 421 },
    { value: 2.5, count: 475 },
    { value: 3, count: 1185 },
    { value: 3.5, count: 2052 },
    { value: 4, count: 2848 },
    { value: 4.5, count: 1336 },
    { value: 5, count: 274 }
  ]),
  new DateField(
    'Review date',
    '1999-05-09T20:00:01.000Z',
    '2012-01-11T02:23:21.000Z'
  )
];

export const workspaces = [
  {
    workspace_id: 'lumi-test',
    name: 'Luminoso Test Workspace',
    permissions: ['write', 'create', 'read']
  },
  {
    workspace_id: 'public',
    name: 'public',
    permissions: ['read']
  }
];

export const project = {
  document_count: 8777,
  creator: 'gfrankowiak@luminoso.com',
  description: 'Test Description',
  language: 'en',
  next_build_language: 'en',
  last_build_info: {
    source_type: 'recalculator',
    success: true,
    stop_time: 1549477158.7246997,
    number: 1,
    start_time: 1549476881.2827606,
    science_version: '2.2',
    sentiment: { success: true }
  },
  workspace_id: 'lumi-test',
  last_metaupdate: 1554829590.4259071,
  name: "Gus's beer advocate",
  permissions: ['create', 'read', 'write'],
  creation_date: 1549476859.938438,
  project_id: 'prnhkxr9',
  last_successful_build_time: 1549477158.7246997,
  totalDocCount: 8777,
  mostRecentJobFailed: false
};

export const readOnlyProject = {
  document_count: 8777,
  creator: 'gfrankowiak@luminoso.com',
  description: 'This project has read only permissions',
  language: 'en',
  next_build_language: 'en',
  last_build_info: {
    source_type: 'recalculator',
    success: true,
    stop_time: 1549477158.7246997,
    number: 1,
    start_time: 1549476881.2827606,
    science_version: '2.2',
    sentiment: { success: true }
  },
  workspace_id: 'public',
  last_metaupdate: 1554829590.4259071,
  name: "Gus's beer advocate with limited permissions",
  permissions: ['read'],
  creation_date: 1549476859.938438,
  project_id: 'pr666666',
  last_successful_build_time: 1549477158.7246997,
  totalDocCount: 8777,
  mostRecentJobFailed: false
};
