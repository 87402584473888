import React from 'react';
import { css } from '@emotion/react';

import SendEmailSelector from '../SendEmailSelector';
import SentimentSelector from '../SentimentSelector';
import { Checkbox } from '../../components/core/Checkbox';
import { PlaintextWhenDisabledDropdown } from '../PlaintextWhenDisabledDropdown';


const intervalOptions = [
  { value: 'day', name: 'Day' },
  { value: 'week', name: 'Week' }
];

const UploadStreamDataActions = ({
                                   username,
                                   sendEmail,
                                   setSendEmail,
                                   sentiment,
                                   setSentiment,
                                   repeat,
                                   setRepeat,
                                   repeatInterval,
                                   setRepeatInterval
                                 }) => {
  return (
    <div style={{ display: 'flex', columnGap: '50px' }}>
      <div>
        {username && (
          <SendEmailSelector
            checked={sendEmail}
            onChange={() => setSendEmail(!sendEmail)}
            email={username}
            additionalCss={css`
                margin-bottom: 0.5rem;
            `}
          />
        )}

        <SentimentSelector
          rebuild={true}
          checked={sentiment}
          onChange={() => setSentiment(!sentiment)}
          additionalCss={css`
              margin-bottom: 0.5rem;
          `}
        />
      </div>

      <div
        css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
            flex-direction: row;
        `}
      >
        <label
          css={css`
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              font-size: inherit;
          `}
        >
          <Checkbox
            checked={repeat}
            css={css`
                margin-right: 0.5rem;
            `}
            aria-label={`select repeat`}
            onChange={(event) => {
              event.stopPropagation();
              setRepeat((prev) => !prev);
              if (!repeat) {
                setRepeatInterval('day');
              } else {
                setRepeatInterval('');
              }
            }}
          />
          <span>Pull new data</span>
        </label>
        <div
          css={css`
              display: flex;
              align-items: center;
              gap: 20px;
          `}
        >
          <PlaintextWhenDisabledDropdown
            editable={repeat}
            value={repeatInterval}
            onChange={setRepeatInterval}
            options={intervalOptions}
            promptOption="Day"
          />
        </div>
      </div>
    </div>
  );
};

export default UploadStreamDataActions;
