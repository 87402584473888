import { css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Tab as ReachTab,
  TabPanel as ReachTabPanel,
  Tabs as ReachTabs
} from '@reach/tabs';

import { Button } from '../components/core/Button';
import { Colors, Mixins } from '../styles';
import Tooltip from '../components/core/Tooltip';

export function TabPanel({
  header,
  body,
  showOverlay = false,
  className,
  ...props
}) {
  return (
    <ReachTabPanel
      {...props}
      as="section"
      css={css`
        background: white;
        ${Mixins.roundedCorners};
        height: 100%;
        &:not([hidden]) {
          display: flex;
          flex-direction: column;
        }
      `}
    >
      <h6
        css={css`
          padding: 0.5rem;
          background: ${Colors.blue4};
          color: white;
          font-weight: normal;
          ${Mixins.roundedCorners};
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          /* Make header tall enough to accommodate SidePanelHeaderButton */
          //height: 1.75rem;
        `}
      >
        {header}
      </h6>
      <div
        aria-hidden={showOverlay}
        className={className}
        css={css`
          flex: 1;
          min-height: 0;
          position: relative;
          overflow: auto;
          ${showOverlay &&
          css`
            &::after {
              ${Mixins.fillViewport};
              /* float this above the rest of the content */
              z-index: 1;
              content: '';
              background: white;
              opacity: 0.8;
            }
          `}
        `}
      >
        {body}
      </div>
    </ReachTabPanel>
  );
}

TabPanel.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  showOverlay: PropTypes.bool,
  className: PropTypes.string
};

export function Tab({ label, ...props }) {
  return (
    <Tooltip
      delayed={false}
      position="right"
      css={css`
        margin-right: 0.5rem;
      `}
      anchor={
        <ReachTab
          as={Button}
          aria-label={label}
          css={css`
            width: 100%;
            box-shadow: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            &[data-selected] {
              background: ${Colors.blue4};
              border-bottom: none;

              svg,
              .fa::before,
              &.fa::before {
                color: white;
              }
            }
          `}
          {...props}
        />
      }
    >
      {label}
    </Tooltip>
  );
}

Tab.propTypes = {
  label: PropTypes.string.isRequired
};

export const Tabs = styled(ReachTabs)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  filter: drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.3));
  /* always at least show the tabs and the header of the panel */
  min-height: 4rem;
  /* Allow elements in side panel to overlay visualization */
  z-index: 1;
`;
