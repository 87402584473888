import React from 'react';
import * as XLSX from 'xlsx';
import { message, Tooltip } from 'antd';
import { css } from '@emotion/react';

import { Icon, IconTypes } from '../../components/icons';
import { getNewListName } from './activeConceptsUtils';
import { loadSharedConceptLists, makeConceptList, openConceptList, shareActiveConceptList } from '../../actions';

const ImportConceptList = ({ sharedConceptLists, projectId }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const dataWithoutHeaders = jsonData.slice(1);

      const groupedConceptLists = new Map();

      dataWithoutHeaders.forEach(row => {
        const rawListName = row[3]?.trim();
        const baseListName = rawListName && rawListName !== 'N/A' ? rawListName : getNewListName(sharedConceptLists);

        if (!groupedConceptLists.has(baseListName)) {
          groupedConceptLists.set(baseListName, []);
        }

        const rawText = row[2] || '';
        let detectedType = 'or';
        if (/\bAND\b/.test(rawText)) {
          detectedType = 'and';
        } else if (/\bOR\b/.test(rawText)) {
          detectedType = 'or';
        }

        const texts = rawText
          .split(/\s+\b(AND|OR)\b\s+/i)
          .map(text => text.replace(/"/g, '').trim())
          .filter(text => !['AND', 'OR'].includes(text.toUpperCase()));

        const concept = {
          name: row[0] || '',
          color: row[1] || '#000000',
          texts,
          type: detectedType
        };

        groupedConceptLists.get(baseListName).push(concept);
      });

      await Promise.all(
        Array.from(groupedConceptLists.entries()).map(([listName, concepts]) =>
          makeConceptList(projectId, listName, concepts)
        )
      )
        .then((value) => {
          if (value) {
            loadSharedConceptLists(projectId)
              return openConceptList(projectId, value[0]?.concept_list_id)
            .then(() => {
              return shareActiveConceptList(projectId, value[0]?.name)
            })
          }
        });

      message.success('Concept Lists uploaded successfully!', 1);
    };

    reader.readAsArrayBuffer(file);
  };


  return (
    <Tooltip title={<div style={{ color: 'black' }}>Upload Concept List</div>} color={'white'} placement="top">
      <div>
        <label
          className="upload-page__field upload-page__field--button"
          htmlFor="upload-page_file-picker"
          css={css`
              display: flex;
              justify-content: space-around;
              align-items: center;
              height: 100%;
          `}
        >
          Upload Concept List
          <Icon type={IconTypes.UPLOAD} />
        </label>
        <input
          id="upload-page_file-picker"
          className="upload-page__field upload-page__field--file"
          type="file"
          accept=".xlsx, .xls, .csv"
          required
          onChange={handleFileUpload}
        />
      </div>
    </Tooltip>
  );
};

export default ImportConceptList;
