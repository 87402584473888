import React, { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { FeatureFlagsContext } from '../FeatureFlagsContext';
import { StoreContext } from '../StoreContext';
import SidePanelSection from '../components/SidePanelSection';
import { NonACSDetails } from './search_details/NonACSDetails';
import {
  SearchDetailsSection,
  TotalMatchesSection
} from './search_details/ACSDetails';
import { TopConcepts } from './search_details/TopConcepts';
import { TabPanel } from './Tabs';
import Alert from '../components/core/Alert';
import { AlertTypes, ConceptTypes } from '../constants';
import { Bold } from '../components/Bold';
import { Colors } from '../styles';
import TextList from '../components/TextList';

export function SearchDetailsPanel({ showOverlay }) {
  const featureFlags = useContext(FeatureFlagsContext);
  return (
    <TabPanel
      header="Search details"
      showOverlay={showOverlay}
      css={css`
        display: grid;
        grid-auto-rows: fit-content(100%);
      `}
      body={
        featureFlags.boolean_search ? (
          <SearchDetailsWithACS />
        ) : (
          <SearchDetailsWithoutACS />
        )
      }
    />
  );
}

function SearchDetailsWithACS() {
  const {
    filterCount,
    filterIsLoading,
    searchInProgress,
    selection,
    conceptSearchResults
  } = useContext(StoreContext);
  const isLoading = filterIsLoading || searchInProgress;
  const isAndTypeConcept = selection?.type === ConceptTypes.AND;

  if (!selection) {
    return <TopConcepts />;
  }

  return (
    <>
      <SearchDetailsBanner />
      <TotalMatchesSection
        activeSearch={Boolean(selection)}
        totalCount={
          conceptSearchResults?.exact.total_count +
          conceptSearchResults?.conceptual.total_count
        }
        documentCount={filterCount}
        isLoading={isLoading}
        dataTestId="total-matches-section"
      />
      <SearchDetailsSection
        isAndTypeConcept={isAndTypeConcept}
        details={conceptSearchResults?.exact}
        documentCount={filterCount}
        header="Exact matches"
        termCountWhileLoading={selection.included.length}
        dataTestId="exact-matches-section"
      />
      <SearchDetailsSection
        details={conceptSearchResults?.conceptual}
        documentCount={filterCount}
        header="Conceptual matches"
        termCountWhileLoading={20}
        tooltip="Exact and conceptual match counts may overlap, since most documents contain more than one concept."
        dataTestId="conceptual-matches-section"
      />
      {selection.excluded.length > 0 && (
        <SearchDetailsSection
          details={conceptSearchResults?.excluded}
          documentCount={filterCount}
          header="Excluded matches"
          termCountWhileLoading={selection.excluded.length}
          dataTestId="excluded-matches-section"
        />
      )}
    </>
  );
}

function SearchDetailsWithoutACS() {
  const { filterCount, filterIsLoading, searchInProgress, selection } =
    useContext(StoreContext);
  const isLoading = filterIsLoading || searchInProgress;

  return (
    <>
      {selection && (
        <SidePanelSection header="Match counts">
          <NonACSDetails
            documentCount={filterCount}
            selection={selection}
            loading={isLoading}
          />
        </SidePanelSection>
      )}
      <TopConcepts />
    </>
  );
}

function SearchDetailsBanner() {
  const { selection, conceptSearchResults } = useContext(StoreContext);
  if (!conceptSearchResults || selection.sharedConceptId) {
    return null;
  }

  const inputTexts = Object.keys(conceptSearchResults.searchTexts);
  const unusableTexts = inputTexts.filter(
    text => conceptSearchResults.searchTexts[text] === ''
  );
  const alteredTexts = inputTexts.filter(
    text =>
      conceptSearchResults.searchTexts[text] !== text &&
      conceptSearchResults.searchTexts[text] !== ''
  );

  return (
    <>
      {alteredTexts.length > 0 && (
        <Alert type={AlertTypes.WARNING} cancellable={true}>
          <ScrollableWrapper>
            {alteredTexts.map(text => (
              <div
                key={text}
                css={css`
                  :not(:last-child) {
                    border-bottom: 1px solid ${Colors.yellow2};
                  }
                `}
              >
                <div>
                  You typed: <Bold>{text}</Bold>
                </div>
                <div>
                  We used: <Bold>{conceptSearchResults.searchTexts[text]}</Bold>
                </div>
              </div>
            ))}
          </ScrollableWrapper>
        </Alert>
      )}
      {unusableTexts.length > 0 && (
        <Alert type={AlertTypes.ERROR} cancellable={true}>
          <ScrollableWrapper>
            <div>
              The following search terms will always return nothing:{' '}
              <TextList
                items={unusableTexts.map(text => (
                  <Bold key={text}>{text}</Bold>
                ))}
              />
            </div>
          </ScrollableWrapper>
        </Alert>
      )}
    </>
  );
}

const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  /* Show 3 lines of text plus most but not all (80%) of the next line. 
     "line-height: normal" generally translates to 1.2 rem according to MDN, 
     but is an inherited property so we hardcode the value to avoid the 
     possibility of accidentally breaking this logic. */
  line-height: 1.2rem;
  max-height: ${4.8 * 1.2}rem;
`;
