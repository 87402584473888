import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import html2canvas from 'html2canvas';

import Tooltip from '../components/core/Tooltip';
import { Button } from '../components/core/Button';
import Spinner from '../components/core/Spinner';
import screenshotIcon from '../../static/img/screenshot.png';

export function PngExportButton() {
  const [isLoading, setIsLoading] = useState(false);
  const [canDoScreen, setCanDoScreen] = useState(false);


  useEffect(() => {
    const checkElements = () => {
      const mainElement =
        document.querySelector('.cloud') ||
        document.querySelector('.scatter-plot__data-points') ||
        document.getElementById('print');
      setCanDoScreen(!!mainElement);
    };

    checkElements();
    const observer = new MutationObserver(checkElements);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  if (!canDoScreen) return <div style={{ width: '56px', height: '56px' }} />;

  return (
    <Tooltip
      position="right"
      anchor={
        <Button
          aria-label="Export as PNG"
          className="axis-control__btn"
          onClick={() => PngExportLogic(setIsLoading)}
          data-tracking-item="galaxy_export-png"
          css={css`
            background: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: none;
            cursor: pointer;
            margin-right: -9px;
            &:hover,
            &:focus,
            &:active {
              background: transparent !important;
              box-shadow: none !important;
              opacity: 1 !important;
            }
          `}
        >
          {isLoading ? (
            <Spinner size="medium" />
          ) : (
            <img
              src={screenshotIcon}
              alt="export as png"
              width={40}
              height={40}
              css={css`
                display: block;
                filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
              `}
            />
          )}
        </Button>
      }
    >
      Export as PNG
    </Tooltip>
  );
}
const inlineStyles = elem => {
  const children = elem.querySelectorAll('*');
  children.forEach(child => {
    const computedStyle = window.getComputedStyle(child);
    child.style.fill = computedStyle.fill;
    child.style.stroke = computedStyle.stroke;
    child.style.strokeDasharray = computedStyle.strokeDasharray;
    child.style.fontFamily = computedStyle.fontFamily;
  });
};

export function PngExportLogic(setIsLoading) {
  setIsLoading(true);

  let element =
    document.querySelector('.cloud') ||
    document.querySelector('.scatter-plot__data-points');
  if (!element) {
    exportToPngPrint(setIsLoading);
  }

  if (
    element?.tagName.toLowerCase() === 'svg' ||
    element?.tagName.toLowerCase() === 'g'
  ) {
    inlineStyles(element);
    const pixelRatio = window.devicePixelRatio || 1;
    if (element.tagName.toLowerCase() === 'g') {
      const wrapperSVG = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
      );
      const yElement = document.querySelector('.scatter-plot__y-axis');
      const xElement = document.querySelector('.scatter-plot__x-axis');
      const overallElement = document.querySelector(
        '.scatter-plot__y-axis-overall'
      );
      inlineStyles(yElement);
      inlineStyles(xElement);
      inlineStyles(overallElement);
      wrapperSVG.appendChild(yElement.cloneNode(true));
      wrapperSVG.appendChild(xElement.cloneNode(true));
      wrapperSVG.appendChild(overallElement.cloneNode(true));
      const bbox = element.getBBox();
      wrapperSVG.setAttribute(
        'viewBox',
        `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
      );
      wrapperSVG.setAttribute('width', bbox.width.toString());
      wrapperSVG.setAttribute('height', bbox.height.toString());
      wrapperSVG.appendChild(element.cloneNode(true));
      element = wrapperSVG;
      wrapperSVG.appendChild(element.cloneNode(true));
      element = wrapperSVG;
      if (pixelRatio > 1) {
        element.style.fontSize = '10px';
      }
    }

    const desiredWidth = 1200;
    const desiredHeight = 700;
    const bbox = element.getBBox();
    const clonedElement = element.cloneNode(true);
    clonedElement.setAttribute('width', bbox.width.toString());
    clonedElement.setAttribute('height', bbox.height.toString());
    const svgData = new XMLSerializer().serializeToString(element);
    const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    exportSVGToPNG(
      url,
      desiredWidth,
      desiredHeight,
      pixelRatio,
      setIsLoading
    );
  }
}

const exportSVGToPNG = (url, width, height, pixelRatio, setLoading) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.onload = () => {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const imgData = canvas.toDataURL('image/png');
    downloadImage(imgData, 'visualization.png');
    setLoading(false);
  };
  img.onerror = () => {
    console.error('Error loading SVG data into image');
    setLoading(false);
  };
  img.src = url;
};

const downloadImage = (imgData, filename) => {
  const link = document.createElement('a');
  link.href = imgData;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getChildrenWithDataType = (element, dataType) => {
  return element.querySelectorAll(`[data-type='${dataType}']`);
};

const exportToPngPrint = (setIsLoading) => {
  const element = document.getElementById('print');
  const childrenWithDataType = getChildrenWithDataType(
    element,
    'transparent'
  );

  if (!element) {
    return;
  }

  const type = element.dataset.type;
  element.style.height = 'auto';
  const setTransparentBackground = element => {
    if (!element.classList.contains('tooltip')) {
      element.style.backgroundColor = 'transparent';

      Array.from(element.children).forEach(child =>
        setTransparentBackground(child)
      );
    }
  };

  if (type === 'transparent') {
    setTransparentBackground(element);
  }

  childrenWithDataType.forEach(child => {
    child.style.opacity = '0';
    child.style.visibility = 'hidden';
  });

  html2canvas(element, {
    useCORS: true,
    backgroundColor: null,
    scale: 2
  }).then(canvas => {
    element.style.height = '100%';
    childrenWithDataType.forEach(child => {
      child.style.opacity = '1';
      child.style.visibility = 'visible';
    });
    const imgData = canvas.toDataURL('image/png');
    downloadImage(imgData, 'visualization.png');
    setIsLoading(false);
  });
};
