import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { css } from '@emotion/react';

import { DateBreakdown, NumericBreakdown } from '../../classes/Breakdowns';
import {
  CategoricalField,
  DateField,
  NumericField,
  ScoreField
} from '../../classes/MetadataFields';
import { Checkbox } from '../../components/core/Checkbox';
import Dropdown from '../../components/core/Dropdown';
import { BubbleRadio } from '../../components/core/Radio';
import Tooltip from '../../components/core/Tooltip';
import {
  isBreakdownFieldDisabled,
  isBucketCountInvalid,
  isStringFieldDisabled,
  numericFieldHasNoRange
} from '../../utils/breakdownValidityUtils';
import { useUniqueId } from '../../utils/hooks';
import { StoreContext } from '../../StoreContext';
import { filterBreakdownField } from '../../utils/filterBreakdown';
import { instanceOf } from '../../drivers/utils';
import { BubbleInput } from '../../components/core/BubbleInput';
import { useSearchParams } from '../../search_params';
import { SettingsLabel } from '../../components/SettingsLabel';
import { WhichConceptsToVisualize } from './WhichConceptsToVisualize';
import { ConfigRadioWrapper, ConfigSectionWrapper } from './ConfigWrapper';
import CalculateMatchCountPercentage from './CalculateMatchCountPercentage';

export function VolumeSettings() {
  return (
    <>
      <WhichConceptsToVisualize />
      <ConfigSectionWrapper>
        <CalculateMatchCountPercentage />
      </ConfigSectionWrapper>
      <ConfigSectionWrapper>
        <IncludeConceptualMatches />
      </ConfigSectionWrapper>
      <ConfigSectionWrapper>
        <HowToVisualizeConceptVolume />
      </ConfigSectionWrapper>
    </>
  );
}

export function IncludeConceptualMatches() {
  const { searchParams, updateSearch } = useSearchParams();
  const includingConceptual = searchParams.match_type === 'total';
  const isShowPrevalence = searchParams.show_prevalence_rank === 'true';

  return (
    <fieldset>
      <SettingsLabel as="legend">Luminoso Science Feature</SettingsLabel>
      <BubbleInput
        as={Checkbox}
        label="Include conceptual matches"
        checked={includingConceptual}
        onChange={() => {
          updateSearch({ match_type: includingConceptual ? 'exact' : 'total' });
        }}
      />
      <BubbleInput
        as={Checkbox}
        label="Show prevalence rank"
        checked={isShowPrevalence}
        onChange={() => {
          updateSearch({ show_prevalence_rank: isShowPrevalence ? 'false' : 'true' });
        }}
      />
    </fieldset>
  );
}

export function HowToVisualizeConceptVolume() {
  const { searchParams, updateSearch } = useSearchParams();
  return (
    <>
      <fieldset>
        <SettingsLabel as="legend">How to visualize concept volume</SettingsLabel>
        <ConfigRadioWrapper>
          <BubbleRadio
            label="Overall concept volume"
            checked={!searchParams.breakdown}
            onChange={() => updateSearch({ breakdown: null })}
          />
        </ConfigRadioWrapper>
        <Breakdown type={DateField} title="Over time" />
        <Breakdown type={CategoricalField} title="By category" />
        <Breakdown type={ScoreField} title="By score value" />
        <Breakdown type={NumericField} title="Across a numeric range" />
      </fieldset>
        <BubbleInput
          as={Checkbox}
          label="Display in Concept color"
          checked={searchParams.concept_color === 'true'}
          onChange={() => {
            updateSearch({
              concept_color: searchParams.concept_color === 'true' ? 'false' : 'true'
            });
          }}
        />
    </>

  );
}

function Breakdown({ type, title }) {
  const { metadata } = React.useContext(StoreContext);
  const { searchParams, updateSearch } = useSearchParams();
  const breakdownField = metadata.find(
    field => field.name === searchParams.breakdown
  );
  const fields = metadata
    .map(field => filterBreakdownField(field, searchParams.filter))
    .filter(instanceOf(type));
  const validFields = fields.filter(field => !isBreakdownFieldDisabled(field));

  return (
    <ConfigRadioWrapper>
      <Tooltip
        disabled={validFields.length > 0}
        anchor={
          <>
            <BubbleRadio
              label={title}
              checked={breakdownField instanceof type}
              onChange={() => updateSearch({ breakdown: validFields[0].name })}
              disabled={validFields.length === 0}
              css={css`
                  &:disabled {
                      /* We ignore pointer events on disabled checkboxes so
                       that the wrapping Tooltip will appear/disappear
                       correctly */
                      pointer-events: none;
                  }
              `}
            />
            {breakdownField instanceof type && (
              <>
                <LabelledSelect
                  label={fieldName(type)}
                  value={breakdownField.name}
                  onChange={event =>
                    updateSearch({
                      breakdown: event.target.value,
                      normalized: null
                    })
                  }
                >
                  {fields.map(field => (
                    <option
                      key={field.name}
                      value={field.name}
                      disabled={isBreakdownFieldDisabled(field)}
                    >
                      {field.name}
                      {field instanceof CategoricalField &&
                        isStringFieldDisabled(field) &&
                        ' (Too many values to display)'}
                      {(field instanceof NumericField ||
                          field instanceof ScoreField) &&
                        numericFieldHasNoRange(field) &&
                        ' (Does not have multiple values)'}
                    </option>
                  ))}
                </LabelledSelect>
                <SelectInterval />
                <SelectNormalized />
              </>
            )}
          </>
        }
      >
        <div
          css={css`
              max-width: 20rem;
          `}
        >
          {fields.length === 0
            ? `There are no ${fieldName(type).toLowerCase()}s in this project`
            : `With your current settings, there are no ${fieldName(
              type
            ).toLowerCase()}s that are valid for breakdowns`}
        </div>
      </Tooltip>
    </ConfigRadioWrapper>
  );
}

Breakdown.propTypes = {
  type: PropTypes.oneOf([DateField, CategoricalField, ScoreField, NumericField])
    .isRequired,
  title: PropTypes.string.isRequired
};

function fieldName(type) {
  switch (type) {
    case NumericField:
      return 'Numeric field';
    case ScoreField:
      return 'Score field';
    case DateField:
      return 'Date field';
    case CategoricalField:
      return 'String field';
  }
}

function SelectInterval() {
  const { metadata } = React.useContext(StoreContext);
  const { searchParams, updateSearch } = useSearchParams();
  const fields = metadata.map(field =>
    filterBreakdownField(field, searchParams.filter)
  );
  const breakdownField = fields.find(
    field => field.name === searchParams.breakdown
  );
  const intervalBreakdowns = getIntervalBreakdowns(breakdownField);

  if (!intervalBreakdowns) {
    return null;
  }

  return (
    <LabelledSelect
      label="Interval"
      value={searchParams.interval}
      onChange={event => updateSearch({ interval: event.target.value })}
    >
      {intervalBreakdowns.map(breakdown => (
        <option
          key={breakdown.interval}
          value={breakdown.interval}
          disabled={isBucketCountInvalid(breakdown)}
        >
          {capitalize(breakdown.interval)}
          {breakdown.getBucketCount() > 100 && ' (Too many values to display)'}
          {breakdown.getBucketCount() <= 1 && ' (Too few values to display)'}
        </option>
      ))}
    </LabelledSelect>
  );
}

function getIntervalBreakdowns(breakdownField) {
  switch (breakdownField.constructor) {
    case DateField:
      return DateBreakdown.INTERVALS.map(
        interval => new DateBreakdown(breakdownField, interval)
      );
    case NumericField:
    case ScoreField:
      return NumericBreakdown.getIntervals(breakdownField).intervals.map(
        interval => new NumericBreakdown(breakdownField, interval)
      );
  }
}

function SelectNormalized() {
  const { searchParams, updateSearch } = useSearchParams();
  return (
    <LabelledSelect
      label="Visualize matches as"
      onChange={({ target }) => updateSearch({ normalized: target.value })}
      value={searchParams.normalized}
    >
      <option value="true">% of each subset</option>
      <option value="false"># of matches in each subset</option>
    </LabelledSelect>
  );
}

function LabelledSelect({ label, ...selectProps }) {
  const id = useUniqueId();

  return (
    <div
      css={css`
          display: flex;
          flex-direction: column;
          margin: 5px 1rem;
      `}
    >
      <SettingsLabel htmlFor={id}>{label}</SettingsLabel>
      <Dropdown
        id={id}
        containerCss={css`
            width: 100%;
        `}
        {...selectProps}
      />
    </div>
  );
}

LabelledSelect.propTypes = {
  label: PropTypes.string.isRequired
};
