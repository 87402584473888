import React, { useContext } from 'react';
import styled from '@emotion/styled';

import { RoutePatterns } from '../../constants';
import { Colors } from '../../styles';
import { FEATURES } from '../icons';
import { NavigationLink } from './NavigationLink';
import { StoreContext } from '../../StoreContext';
import { FeatureFlagsContext } from '../../FeatureFlagsContext';
import { TabSidebarPanel } from '../../TabSidebarPanel/TabSidebarPanel';
import { useCurrentView } from '../../side_panel/views/view';

export const Navigation = () => {
  const { project } = useContext(StoreContext);
  const { view } = useCurrentView();
  const featureFlags = useContext(FeatureFlagsContext);
  const isVisibleManagment = project?.permissions?.includes('write')
  let featuresToShow =  FEATURES.filter(feature => feature !== 'DOUBLE_GEARS');
  const allowedFeatures = ['volume', 'galaxy', 'sentiment', 'drivers'];
  const showPanel = allowedFeatures.includes(view.feature);
  return (
    <Container
      showPanel={showPanel}
      featureCount={
        !featureFlags.less_clicks_btn ? FEATURES.length - 1 : FEATURES.length
      }
    >
      {featuresToShow.map(feature => (
        <NavigationLink
          key={feature}
          icon={feature}
          routePattern={ROUTE_PATTERN_BY_FEATURE[feature]}
          label={FEATURE_LABELS[feature]}
          data-tracking-item={`explorer-sub-nav_feature-item_${feature}`}
        />
      ))}
      <TabSidebarPanel/>
        {isVisibleManagment &&
        <NavigationLink
          key={'DOUBLE_GEARS'}
          icon={'DOUBLE_GEARS'}
          routePattern={ROUTE_PATTERN_BY_FEATURE['DOUBLE_GEARS']}
          label={FEATURE_LABELS['DOUBLE_GEARS']}
          data-tracking-item={`explorer-sub-nav_feature-item_${'DOUBLE_GEARS'}`}
        />
      }
    </Container>
  );
};

const FEATURE_LABELS = {
  HIGHLIGHTS: 'Highlights',
  VOLUME: 'Volume',
  GALAXY: 'Galaxy',
  DRIVERS: 'Drivers',
  SENTIMENT: 'Sentiment',
  DASHBOARD: 'Dashboard',
  DOUBLE_GEARS: 'Project management'
};

const ROUTE_PATTERN_BY_FEATURE = {
  HIGHLIGHTS: RoutePatterns.HIGHLIGHTS,
  VOLUME: RoutePatterns.VOLUME,
  GALAXY: RoutePatterns.GALAXY,
  DRIVERS: RoutePatterns.DRIVERS,
  SENTIMENT: RoutePatterns.SENTIMENT,
  DASHBOARD: RoutePatterns.DASHBOARD,
  DOUBLE_GEARS: RoutePatterns.PROJECT_MANAGEMENT
};

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(${props => props.featureCount - 1}, 3rem) auto;
  gap: 1rem;
  padding: 0.5rem;
  padding-left: 0;
  border-right: 1px solid ${Colors.gray1};
    ${props =>
            !props.showPanel &&
            `
    > :last-child {
      place-self: end;
    }
  `}
`;
