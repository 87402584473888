import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { TabList } from '@reach/tabs';

import { StoreContext } from '../StoreContext';
import { selectSidePanelTab } from '../actions';
import { SidePanelTabIcon } from '../side_panel/TabTitles';
import { PngExportButton } from './PngExportButton';
import { Tab, Tabs } from '../side_panel/Tabs';
import { useCurrentView } from '../side_panel/views/view';
import { Colors } from '../styles';

export function TabSidebarPanel() {
  const { selectedSidePanelTab } = useContext(StoreContext);
  const { view } = useCurrentView();
  const { projectHasLoaded } = React.useContext(StoreContext);

  const tabs = [
    { key: 'configure', label: 'Configure' },
    { key: 'activeConcepts', label: 'Concepts' },
    { key: 'filter', label: 'Filter' },
    { key: 'search', label: 'Search' },
    { key: 'documents', label: 'Documents' },
    { key: 'export', label: 'Export' }
  ];

  const tabKeys = tabs.map(tab => tab.key);
  const selectedTabIndex = tabKeys.indexOf(selectedSidePanelTab);

  function selectTabByIndex(index) {
    selectSidePanelTab(tabKeys[index]);
  }

  const allowedFeatures = ['volume', 'galaxy', 'sentiment', 'drivers'];
  const showPanel = allowedFeatures.includes(view.feature) && projectHasLoaded;
  if (!showPanel) {
    return null;
  }

  return (
    <>
      <SidebarContainer>
        <Tabs index={selectedTabIndex} onChange={selectTabByIndex} orientation="vertical">
          <TabListContainer>
            {tabs.map(tab => (
              <StyledTab
                key={tab.key}
                label={tab.label}
              >
                <Indicator active={selectedSidePanelTab === tab.key} />
                <Container active={selectedSidePanelTab === tab.key}>
                  <SidePanelTabIcon tab={tab.key} size={26} />
                </Container>
              </StyledTab>
            ))}
          </TabListContainer>
        </Tabs>
      </SidebarContainer>
        <MenuItem active={false}>
          <Container active={false}>
            <PngExportButton />
          </Container>
        </MenuItem>
    </>
  );
}

const MenuItem = styled.div`
    padding: 0;
    display: flex;
    margin-right: -0.5rem;
    grid-template-columns: 0.5rem auto;
    justify-content: center;
    width: 100%;
    height: 3rem;
    -webkit-transition: allease-in-out 100ms;
    transition: allease-in-out 100ms;
    cursor: pointer;
    align-items: center;
`;
const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40%
`;

const TabListContainer = styled(TabList)`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: none;
    position: relative;
    bottom: 30px;
`;

const StyledTab = styled(Tab)`
    padding: 0;
    display: grid;
    background: inherit !important;
    grid-template-columns: 0.5rem auto;
    justify-content: space-between;
    gap: 0.5rem;
    width: 3.75rem;
    height: 3rem;
    transition: all ease-in-out 100ms;
    cursor: pointer;
`;

const Indicator = styled.div`
    height: 100%;
    width: 0.3rem;
    border-radius: 0 3rem 3rem 0;
    background: ${props => (props.active ? Colors.blue5 : 'transparent')};
`;

const Container = styled.div`
    display: grid;
    width: ${props => (props.active ? '4.5rem' : '3rem')};
    height: 3rem;
    place-content: center;
    place-items: center;
    border-radius: 0.25rem;
    background: ${props => (props.active ? Colors.blue5 : 'transparent')};
    color: ${props => (props.active ? Colors.blue7 : Colors.gray9)};

    svg {
        margin: 0;
        color: black;

        path {
            stroke-width: 0.7;
        }
    }
`;

export default TabSidebarPanel;
