import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../components/core/Button';
import { selectSidePanelTab } from '../actions';
import { Icon, IconTypes } from '../components/icons';

export function TabLink({ tab, className, withLabel = true, onClick }) {
  return (
    <Button
      flavor="subtle"
      padded={false}
      onClick={() => {
        selectSidePanelTab(tab);
        onClick?.();
      }}
      className={className}
      aria-label={labelForTab(tab)}
    >
      <SidePanelTabIcon size="0.875rem" tab={tab} />
      {withLabel && labelForTab(tab)}
    </Button>
  );
}
TabLink.propTypes = {
  tab: PropTypes.string.isRequired,
  className: PropTypes.string,
  withLabel: PropTypes.bool,
  onClick: PropTypes.func
};

export function labelForTab(tab) {
  switch (tab) {
    case 'search':
      return 'Search details';
    case 'activeConcepts':
      return 'Active concepts';
    case 'filter':
      return 'Filter documents';
    case 'configure':
      return 'Configure visualization';
    case 'documents':
      return 'Documents';
    case 'export':
      return 'Export';
    default:
      throw Error(`Invalid tab: ${tab}`);
  }
}

export function SidePanelTabIcon({ tab, ...props }) {
  return <Icon  type={getIconType(tab)} {...props} thickness={tab === 'filter' ? 'light' : undefined}/>;
}

function getIconType(tab) {
  switch (tab) {
    case 'search':
      return IconTypes.SEARCH;
    case 'activeConcepts':
      return IconTypes.LIST;
    case 'filter':
      return IconTypes.FUNNEL;
    case 'configure':
      return IconTypes.GEAR;
    case 'documents':
      return IconTypes.DOCUMENTS;
    case 'export':
      return IconTypes.DOWNLOAD;
    default:
      throw Error(`Invalid tab: ${tab}`);
  }
}
