import React from 'react';
import styled from '@emotion/styled';

const StreamCategory = ({
                          sources = [],
                          subCategories = [],
                          handleAddSource,
                        }) => {
  return (
    <CategoryWrapper>
      <SourceRow/>
      {subCategories.length > 0
        ? subCategories.map((sub) => (
          <SubCategory
            key={sub.title}
            title={sub.title}
            sources={sub.sources}
            handleAddSource={handleAddSource}
          />
        ))
        : sources.map((source) => (
          <SourceItem
            key={source.name}
            {...source}
            handleAddSource={handleAddSource}
          />
        ))}
    </CategoryWrapper>
  );
};

export default StreamCategory;

const SubCategory = ({ sources, handleAddSource }) => (
  <>
    {sources.map((source) => (
      <SourceItem
        key={source.name}
        {...source}
        handleAddSource={handleAddSource}
      />
    ))}
  </>
);

const SourceItem = ({ name, icon, color, handleAddSource }) => (
  <ItemRow>
    <AddButton
      onClick={() => handleAddSource({ name, icon, color })}
    >
    </AddButton>
    <ItemLabel>
      {name}
    </ItemLabel>
  </ItemRow>
);
const ItemLabel = styled.div`
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    max-width: 140px;
`;

const ItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 12px;
    margin-bottom: 10px;
`;
const AddButton = styled.button`
    position: relative;
    width: 28px;
    height: 28px;
    background: #126715;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease, box-shadow 0.2s ease, transform 0.1s ease;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);

    &::after {
        content: '';
        position: absolute;
        transform: translate(-50%, -50%);
        height: 3px;
        width: 50%;
        background: #ffffff;
        top: 50%;
        left: 50%;
    }

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        height: 50%;
        width: 3px;
    }

    &:hover {
        background-color: #165517;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.4);
        transform: scale(1.05);
    }

    &:hover::before,
    &:hover::after {
        background: #ffffff;
        transition: 0.2s;
    }

    &:active {
        background-color: #0f4512;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        transform: scale(0.95);
    }
`;






const CategoryWrapper = styled.div`
    margin-bottom: 20px;
`;

const SourceRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
`;

