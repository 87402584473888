import _ from 'lodash';
import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { TabPanel } from './Tabs';
import { Button } from '../components/core/Button';
import Spinner from '../components/core/Spinner';
import Alert from '../components/core/Alert';
import { AlertTypes } from '../constants';
import SimpleModal from '../components/core/SimpleModal';
import { StoreContext } from '../StoreContext';
import { notifier } from '../actions';
import {
  downloadDocuments,
  downloadConceptFilterAssociations,
  downloadConceptConceptAssocScores, downloadDrivers, downloadSentiment, downloadVolumeSpreadsheet
} from '../utils/ApiUtilsV5';
import ConceptFilterAssociationsForm from './export_and_share/ConceptFilterAssociationsForm';
import ConceptConceptAssociationsForm from './export_and_share/ConceptConceptAssociationsForm';
import { useMountedRef } from '../utils/hooks';
import SidePanelSection from '../components/SidePanelSection';
import { Mixins } from '../styles';
import { BubbleRadio } from '../components/core/Radio';
import DocDownloadModal from '../components/DocDownloadModal';
import { useFilter, useSearchParams } from '../search_params';
import Tooltip from '../components/core/Tooltip';
import { Icon, IconTypes } from '../components/icons';
import { BubbleInput } from '../components/core/BubbleInput';
import { Checkbox } from '../components/core/Checkbox';
import { useCurrentView } from './views/view';
import { metadata } from '../highlights/__mocks__/store';
import { getBreakdowns } from '../Volume';
import { getSentimentStatus, SENTIMENT_STATUS } from '../utils/sentimentStatus';

export function ExportPanel() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [exportIsPending, setExportIsPending] = useState(false);
  const [modalQueue, setModalQueue] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [completedExports, setCompletedExports] = useState(new Set());

  const { project, selection, activeConcepts } = useContext(StoreContext);
  const { projectId } = useParams();
  const { searchParams } = useSearchParams();
  const { conceptSelector } = useCurrentView();

  const matchType = searchParams.match_type === 'total' ? 'both' : 'exact';
  const breakdownField = _.find(metadata, { name: searchParams.breakdown });
  const breakdowns = getBreakdowns(breakdownField, searchParams.interval);
  const filter = useFilter();
  const sentimentStatus = getSentimentStatus(project);
  const isSentimentReady = sentimentStatus === SENTIMENT_STATUS.READY;

  const baseExportOptions = [
    { key: 'documentDownload', label: 'Document Download', description: 'All document verbatims with associated metadata' },
    {
      key: 'volumeExport', label: 'Volume Export', description: (
        <>
          Table of total and exact match counts and the percentage of documents
          covered by selected concepts.{" "}
          <span style={{ fontStyle: "italic" }}>
          (Note: percentage is over total documents, not the filtered subsets)
        </span>
        </>
      ),
    },
    { key: 'driversExport', label: 'Drivers Export', description: (
        <>
          Table of concepts, average scores, the difference from dataset average,{" "}
          <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
          Volume Export
        </span>{" "}
          columns, and 3 example verbatims per concept
        </>
      ),
    },
  ];

  const galaxyExportOptions = [
    { key: 'galaxyConceptExport', label: 'Galaxy Concept-Concept Export', description: 'Heatmap reflecting concept relationships to other concepts using association scores' },
    { key: 'galaxyFilterExport', label: 'Galaxy Concept-Filter Export', description: 'Heatmap reflecting concept relationships to filters using association scores' }
  ];

  const sentimentExportOptions = [
    { key: 'sentimentExport', label: 'Sentiment for Concept Export', description: 'Table of verbatims with sentiment color-coded for detected sentiment, selected concept, and detected Sentiment category (negative, neutral, positive)' },
    { key: 'sentimentDistribution', label: 'Sentiment Distribution Table Export', description: 'Table of concepts, number of exact matches for the concept, counts for each sentiment category, percentage reflecting sentiment distribution per concept, and 3 example verbatims per concept' }
  ];

  const exportOptions = isSentimentReady
    ? [...baseExportOptions, ...galaxyExportOptions, ...sentimentExportOptions]
    : [...baseExportOptions, ...galaxyExportOptions];

  const toggleOption = (key) => {
    setSelectedOptions((prev) =>
      prev.includes(key) ? prev.filter((option) => option !== key) : [...prev, key]
    );
  };

  const wrapDownloader = (downloadFunction, key) => {
    return async (...args) => {
      if (completedExports.has(key)) return;

      setExportIsPending(true);
      setShowModal(false);

      try {
        await downloadFunction(...args);
        setCompletedExports((prev) => new Set(prev).add(key));
      } catch {
        notifier.error("Analysis export failed");
      } finally {
        setExportIsPending(false);

        setModalQueue((prevQueue) => {
          const updatedQueue = prevQueue.slice(1);

          if (updatedQueue.length > 0) {
            setTimeout(() => {
              setModalType(updatedQueue[0]);
              setShowModal(true);
            }, 200);
          } else {
            processExportQueue();
          }

          return updatedQueue;
        });
      }
    };
  };

  const downloadCurrentDocuments = wrapDownloader(
    _.partial(downloadDocuments, projectId, project.name, selection, filter),
    "documentDownload"
  );

  const downloadConceptFilter = wrapDownloader(
    _.partial(downloadConceptFilterAssociations, projectId, project.name),
    "galaxyFilterExport"
  );

  const downloadConceptConcept = wrapDownloader(
    _.partial(downloadConceptConceptAssocScores, projectId, project.name),
    "galaxyConceptExport"
  );

  const processExportQueue = async () => {
    setExportIsPending(true)
    for (const option of selectedOptions) {
      if (!completedExports.has(option)) {
        switch (option) {
          case "volumeExport":
            await downloadVolumeSpreadsheet(projectId, project.name, conceptSelector, searchParams.filter, breakdowns);
            break;
          case "driversExport":
            await downloadDrivers(projectId, project.name, conceptSelector, searchParams.drivers_of, matchType, searchParams.filter);
            break;
          case "sentimentDistribution":
            await downloadSentiment(projectId, project.name, conceptSelector, filter);
            break;
          case "sentimentExport":
            await downloadSentiment(projectId, project.name, conceptSelector, filter, selection);
            break;
        }
        setCompletedExports((prev) => new Set(prev).add(option));
      }
    }

    setExportIsPending(false);
    setSelectedOptions([]);
  };

  const handleExport = async () => {
    setExportIsPending(true);
    setCompletedExports(new Set());

    const priorityOptions = ['documentDownload', 'galaxyConceptExport', 'galaxyFilterExport'];

    const newModalQueue = [...selectedOptions].sort((a, b) => {
      return priorityOptions.includes(a) && !priorityOptions.includes(b) ? -1 : 1;
    }).filter((option) => priorityOptions.includes(option));

    setModalQueue(newModalQueue);

    if (newModalQueue.length > 0) {
      setModalType(newModalQueue[0]);
      setShowModal(true);
    } else {
      processExportQueue();
    }
  };

  const handleModalClose = async () => {
    setShowModal(false);
    setModalQueue((prevQueue) => {
      const updatedQueue = prevQueue.slice(1);

      if (updatedQueue.length > 0) {
        setTimeout(() => {
          setModalType(updatedQueue[0]);
          setShowModal(true);
        }, 100);
      } else {
        processExportQueue();
      }

      return updatedQueue;
    });
  };


  return (
    <TabPanel
      header="Export"
      body={
        <div css={css`display: flex;
            flex-direction: column;
            padding: 15px;
            gap: 5px;`}>
          <div css={css`display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;`}>
            <span css={css`font-size: 14px;
                color: #333;
                width: 75%;`}>
              Exports produce a spreadsheet of the visualized concepts and reflect the filters set in your view.
            </span>
            <Button
              css={css`margin-top: 0.5rem;
                  margin-right: 0.5rem;`}
              onClick={handleExport}
              disabled={exportIsPending}
            >
              {exportIsPending ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
              Export
            </Button>
          </div>

          {exportOptions.map((option) => (
            <BubbleInput
              css={css`background: none;`}
              key={option.key}
              as={Checkbox}
              label={
                <>
                  <strong>{option.label}</strong>
                  <p css={css`font-size: 12px;
                      color: #666;
                      margin: 2px 0 0 0;`}>
                    {option.description}
                  </p>
                </>
              }
              checked={selectedOptions.includes(option.key)}
              onChange={() => toggleOption(option.key)}
            />
          ))}
          {modalType === 'galaxyFilterExport' && (
            <SimpleModal
              onHide={handleModalClose}
              isOpen={showModal}
              header={<h5>Export concept-filter association scores</h5>}
            >
              <ConceptFilterModalContent
                triggerExport={downloadConceptFilter}
                activeConcepts={activeConcepts}
              />
            </SimpleModal>
          )}
          {modalType === 'galaxyConceptExport' && (
            <SimpleModal
              onHide={handleModalClose} isOpen={showModal}
              header={<h5>Export concept-concept association scores</h5>}>
              <ConceptConceptModalContent
                triggerExport={downloadConceptConcept}
                activeConcepts={activeConcepts} />
            </SimpleModal>
          )}
          {modalType === 'documentDownload' && (
            <DocDownloadModal
              onHide={handleModalClose}
              showing={showModal}
              startDownload={downloadCurrentDocuments} />
          )}
        </div>
      }
    />
  );
}


ExportPanel.propTypes = {
  featureSpecificDownloadButton: PropTypes.node
};

export function ExportCurrentViewSection({
                                           featureName,
                                           exportFunction,
                                           disableExport,
                                           disabledMessage
                                         }) {
  const [exportIsPending, setExportIsPending] = useState(false);
  const currentExportRequest = useRef();
  const mounted = useMountedRef();

  function handleDownload() {
    setExportIsPending(true);
    currentExportRequest.current = exportFunction()
      .catch(() => notifier.error('Export failed'))
      .finally(() => {
        if (mounted.current) {
          setExportIsPending(false);
        }
      });
  }

  useEffect(() => {
    return () => {
      if (currentExportRequest.current && currentExportRequest.current.abort) {
        currentExportRequest.current.abort();
      }
    };
  }, []);

  const exportButton = (
    <Button
      css={css`
          ${Mixins.ellipsify}
          &:disabled {
              pointer-events: none;
          }
      `}
      onClick={handleDownload}
      disabled={exportIsPending || disableExport}
      data-tracking-item="download-share-pane_download-xlsx-button"
    >
      {exportIsPending ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
      Export
    </Button>
  );

  return (
    <PanelSection header="Export current selections as spreadsheet">
      <div>{featureName}</div>
      {disableExport && disabledMessage ? (
        <Tooltip anchor={exportButton}>{disabledMessage}</Tooltip>
      ) : (
        exportButton
      )}
    </PanelSection>
  );
}

ExportCurrentViewSection.propTypes = {
  featureName: PropTypes.string.isRequired,
  exportFunction: PropTypes.func.isRequired,
  disableExport: PropTypes.bool,
  disabledMessage: PropTypes.string
};

function PanelSection({ header, children }) {
  return (
    <SidePanelSection header={header} asList={false}>
      <div
        css={css`
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-bottom: 0.5rem;
        `}
      >
        {children}
      </div>
    </SidePanelSection>
  );
}

function CopyMessage({ status }) {
  return (
    <Alert
      css={css`
          flex: 1;
      `}
      type={status === 'success' ? AlertTypes.SUCCESS : AlertTypes.WARNING}
    >
      {status === 'success'
        ? 'Link copied to clipboard!'
        : 'Unable to copy to clipboard'}
    </Alert>
  );
}

CopyMessage.propTypes = {
  status: PropTypes.oneOf(['success', 'failure']).isRequired
};

function RawDataExports() {
  const [selectedExport, setSelectedExport] = useState('documents');

  return (
    <SidePanelSection header="Export raw data as spreadsheet" asList={false}>
      <div
        css={css`
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        `}
      >
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Current documents"
          checked={selectedExport === 'documents'}
          onChange={() => setSelectedExport('documents')}
        />
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Concept-Filter association scores"
          checked={selectedExport === 'concept-filter'}
          onChange={() => setSelectedExport('concept-filter')}
        />
        <BubbleRadio
          name="Export raw data as spreadsheet"
          label="Concept-Concept association scores"
          checked={selectedExport === 'concept-concept'}
          onChange={() => setSelectedExport('concept-concept')}
        />
        <DownloadExportsButton selectedExport={selectedExport} />
      </div>
    </SidePanelSection>
  );
}

function DownloadExportsButton({ selectedExport }) {
  const { projectId } = useParams();
  const { selection, project, activeConcepts } = useContext(StoreContext);
  const filter = useFilter();
  const [showModal, setShowModal] = useState(false);
  const [exportIsPending, setExportIsPending] = useState(false);

  const wrapDownloader = downloadFunction => {
    return (...args) => {
      setExportIsPending(true);
      setShowModal(false);
      downloadFunction(...args)
        .catch(() => notifier.error('Analysis export failed'))
        .finally(() => setExportIsPending(false));
    };
  };
  const downloadCurrentDocuments = wrapDownloader(
    _.partial(downloadDocuments, projectId, project.name, selection, filter)
  );
  const downloadConceptFilter = wrapDownloader(
    _.partial(downloadConceptFilterAssociations, projectId, project.name)
  );
  const downloadConceptConcept = wrapDownloader(
    _.partial(downloadConceptConceptAssocScores, projectId, project.name)
  );

  const exportButton = (
    <Button
      css={css`
          margin-top: 0.5rem;
          margin-right: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          float: right;
      `}
      onClick={() => setShowModal(true)}
      disabled={exportIsPending}
      data-tracking-item={`analysis-exports_${selectedExport}_open-modal`}
    >
      {exportIsPending ? <Spinner /> : <Icon type={IconTypes.DOWNLOAD} />}
      Export
    </Button>
  );

  if (selectedExport === 'documents') {
    return (
      <>
        {exportButton}
        <DocDownloadModal
          onHide={() => setShowModal(false)}
          showing={showModal}
          startDownload={downloadCurrentDocuments}
        />
      </>
    );
  }

  const header =
    selectedExport === 'concept-filter'
      ? 'Export concept-filter association scores'
      : 'Export concept-concept association scores';

  return (
    <>
      {exportButton}
      <SimpleModal
        onHide={() => setShowModal(false)}
        isOpen={showModal}
        trackingPrefix={`analysis-exports_${selectedExport}`}
        header={<h5>{header}</h5>}
      >
        {selectedExport === 'concept-filter' ? (
          <ConceptFilterModalContent
            triggerExport={downloadConceptFilter}
            activeConcepts={activeConcepts}
          />
        ) : (
          selectedExport === 'concept-concept' && (
            <ConceptConceptModalContent
              triggerExport={downloadConceptConcept}
              activeConcepts={activeConcepts}
            />
          )
        )}
      </SimpleModal>
    </>
  );
}

function ConceptFilterModalContent({ triggerExport, activeConcepts }) {
  if (_.isEmpty(activeConcepts)) {
    return (
      <p>
        This project doesn't have enough active concepts! To create some, select
        a concept and use the "Add to active concepts" button.
      </p>
    );
  }

  return <ConceptFilterAssociationsForm onDownload={triggerExport} />;
}

function ConceptConceptModalContent({ triggerExport, activeConcepts }) {
  if (activeConcepts.length < 2) {
    return (
      <p>
        This project doesn't have enough active concepts! To create some, select
        a concept and use the "Add to active concepts" button.
      </p>
    );
  }

  return <ConceptConceptAssociationsForm onDownload={triggerExport} />;
}
